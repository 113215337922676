import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'carbon-control-errors',
  templateUrl: './control-errors.component.html',
  styleUrls: ['./control-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorsComponent {
  protected _text: string | null = '';
  protected _hide = false;

  @Input() set text(value: string | null) {
    if (value !== this._text) {
      this._text = value;
      this._hide = !value;
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
