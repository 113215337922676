import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Subject, debounceTime, of, shareReplay, takeUntil, tap } from 'rxjs';
import { CustomErrorService } from 'src/app/core/services/custom-error.service';
import { RequestsService } from 'src/app/pages/requests/services/requests.service';

import { EMAIL_PATTERN } from '../../../../core/constants/help-menu.constants';
import { AuthCognitoService } from '../../../../core/services/auth-cognito.service';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoadingService } from '../../../../services';
import { ErrorConfig } from '../../../../shared/directives/control-errors/control-errors.token';
import { untilDestroy } from '../../../../shared/utilities';
import { isShift } from '../../../../shared/utilities/common-util/common-utils';
import { setUserProfile } from '../../../../store/user-profile/actions/user-profile.actions';
import { FOOTER_LINK_WEBSITE_URL } from '../../login.constants';

interface LoginForm {
  username: string;
  password: string;
}

@Component({
  selector: 'carbon-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService, LoadingService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent extends untilDestroy(class {}) implements AfterViewInit, OnInit {
  loginForm: FormGroup;
  ssoLoginForm: FormGroup;
  isLoading$ = this.loadingService.isLoading$;
  hasError$$ = new Subject<boolean>();
  hasError$ = this.hasError$$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  inboundId: string | null = null;
  isShift: boolean;
  isHubspotFormVisible$ = new BehaviorSubject<boolean>(false);
  isSsoLogin$ = new BehaviorSubject<boolean>(false);
  errorMessage: string | null = null;
  emailErrors: ErrorConfig = {
    required: () => 'Login.LoginForm.EmailRequiredError',
    email: () => 'Login.LoginForm.EmailPatternError',
    pattern: () => 'SignUp.Form.EmailPatternError',
  };
  passwordErrors: ErrorConfig = {
    required: () => 'Login.LoginForm.PasswordRequiredError',
  };
  websiteUrl = FOOTER_LINK_WEBSITE_URL;

  constructor(
    private router: Router,
    private authService: AuthCognitoService,
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private loadingService: LoadingService,
    private store: Store,
    private route: ActivatedRoute,
    private requestsService: RequestsService,
    private customErrorService: CustomErrorService,
    private translate: TranslateService,
  ) {
    super();
    this.loginForm = this.createLoginForm();
    this.ssoLoginForm = this.createSSOLoginForm();
    this.ssoLoginForm.valueChanges
      .pipe(debounceTime(300), takeUntil(this.destroy$$))
      .subscribe(() => {
        this.customErrorService.clearError();
      });
    this.loginForm.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$$)).subscribe(() => {
      this.customErrorService.clearError();
    });
    this.route?.queryParamMap
      .pipe(
        takeUntil(this.destroy$$),
        tap((params) => {
          if (params.has('Pcf') && params.get('Pcf')) {
            this.inboundId = params.get('Pcf');
          }
        }),
      )
      .subscribe((data) => data);

    // Checks if current client is Shift
    this.isShift = isShift();
  }

  ngOnInit() {
    // Subscribe to error messages
    this.customErrorService.loginError$.subscribe((message) => {
      if (message?.includes('Invalid SAML')) {
        this.errorMessage = this.translate.instant('Login.LoginForm.UserNotCreated');
      } else {
        this.errorMessage = message;
      }
    });
  }

  ngAfterViewInit(): void {
    // Listen to changes in the URL fragment
    this.route.fragment.pipe(takeUntil(this.destroy$$)).subscribe((fragment) => {
      if (fragment === 'free-trial') {
        this.isHubspotFormVisible$.next(true);
      } else {
        this.isHubspotFormVisible$.next(false);
      }
    });

    // Listen to changes in the visibility of the Hubspot form
    this.isHubspotFormVisible$.pipe(takeUntil(this.destroy$$)).subscribe((isVisible) => {
      if (isVisible) {
        this.loadHubspotForm();
      } else {
        this.unloadHubspotForm();
      }
    });
  }

  login(): void {
    if (this.loginForm.valid) {
      of(this.loginForm.value)
        .pipe(
          this.loadingService.loadingSwitchMap((formData: LoginForm) =>
            this.authService.signIn(formData.username.toLowerCase(), formData.password),
          ),
        )
        .subscribe(
          (user) => {
            const { given_name, family_name, email } = user.attributes;
            const profile = {
              firstName: given_name || 'First name',
              lastName: family_name || 'Last name',
              email,
            };
            this.store.dispatch(setUserProfile({ profile }));
            if (this.inboundId) {
              this.router.navigateByUrl(`/requests/overview/inbound?Pcf=${this.inboundId}`);
            } else {
              this.router.navigateByUrl('/products/overview');
            }
          },
          (e) => {
            this.customErrorService.setError(
              this.translate.instant('Login.LoginForm.LoginErrorMessage'),
            );
            this.logger.logError(`Attempt to login: failed, ${e}`);
          },
        );
    }
  }

  // Function to add the #free-trial fragment from the URL
  // This is used to open the Hubspot form by clicking the respoective button
  addFreeTrialFragmentToUrl(): void {
    this.router.navigate([], {
      fragment: 'free-trial',
      queryParamsHandling: 'preserve',
    });
  }

  // Function to remove the #free-trial fragment from the URL
  // This is used to close the Hubspot form by clicking the respoective button
  removeFreeTrialFragmentFromUrl(): void {
    this.router.navigate([], {
      fragment: undefined,
      queryParamsHandling: 'preserve',
    });
  }

  // Function to load hubspot form HTML content and script into the DOM
  private loadHubspotForm(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';

    script.onload = () => {
      const hbspt = (window as Window & typeof globalThis as any)?.hbspt;
      if (hbspt) {
        hbspt.forms.create({
          region: 'eu1',
          portalId: '26884870',
          formId: '42b2b726-b4a4-4875-a23f-d1ba05bd45b1',
          target: '#hubspotForm',
        });
      } else {
        this.isHubspotFormVisible$.next(false);
      }
    };

    document.body.appendChild(script);
  }

  loginWithSSO() {
    this.isSsoLogin$.next(true);
    this.customErrorService.clearError();
  }

  backToSignIn() {
    this.isSsoLogin$.next(false);
    this.customErrorService.clearError();
  }

  signInWithSSO() {
    if (this.ssoLoginForm.valid) {
      of(this.ssoLoginForm.value)
        .pipe(
          this.loadingService.loadingSwitchMap((formData: LoginForm) =>
            this.requestsService.getProviderByEmail(formData.username.toLowerCase()),
          ),
        )
        .subscribe(
          (response) => {
            const provider = response.body.data.provider;
            this.authService.federatedSignInWithSAML(provider);
          },
          (e) => {
            this.customErrorService.setError(
              this.translate.instant('Login.LoginForm.SignInNotAvailable'),
            );
            this.logger.logError(`Attempt to login SSO: failed, ${e}`);
          },
        );
    }
  }

  // Function to remove hubspot form HTML content and script from the DOM
  private unloadHubspotForm(): void {
    const hubspotForm = document.getElementById('hubspotForm');
    if (hubspotForm) {
      hubspotForm.innerHTML = '';
    }

    const script = document.querySelector('script[src="//js-eu1.hsforms.net/forms/embed/v2.js"]');
    if (script) {
      script.remove();
    }
  }

  private createLoginForm() {
    return this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]],
      password: ['', [Validators.required]],
      rememberMe: [true],
    });
  }

  private createSSOLoginForm() {
    return this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]],
    });
  }
}
