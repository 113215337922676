import { CrossSectoralStandard } from '../../models/add-product/product-footprint-form.interface';

export const CROSS_SECTORAL_STANDARDS: CrossSectoralStandard[] = [
  {
    label: 'GHG Protocol Product Standard',
    value: 'GHG Protocol Product standard',
  },
  {
    label: 'ISO 14067',
    value: 'ISO 14067',
  },
  {
    label: 'ISO 14044',
    value: 'ISO 14044',
  },
  {
    label: 'PAS 2050',
    value: 'PAS 2050',
  },
];

/**
 * The standard followed to account for biogenic emissions and removals
 */
export const BIOGENIC_ACCOUNTING_STANDARDS: CrossSectoralStandard[] = [
  {
    label: 'PEF',
    value: 'PEF',
  },
  {
    label: 'ISO',
    value: 'ISO',
  },
  {
    label: 'GHGP',
    value: 'GHGP',
  },
  {
    label: 'Quantis',
    value: 'Quantis',
  },
];

export const SUFFIX_EMISSION = 'kg CO₂e';
export const SUFFIX_CARBON_CONTENT = 'kgC';
export const SUFFIX_WEIGHT = 'kg';

export const EMISSION_MIN_FRACTION_DIGIT = 1;
export const EMISSION_MAX_FRACTION_DIGIT = 10;

export const PRODUCT_DETAILS_TAB = 0;
export const SUPPLY_CHAIN_TAB = 2;
export const ACTIVITY_TAB = 3;

export const PRIMARY_DATA_SHARE_FULLY_CAPACITY = 100;

export const ADD_BIOGENIC_EMISSIONS = 'addBiogenicEmissions';

export const SUPPLY_ADD_BIOGENIC_EMISSIONS = 'supplyAddBiogenicEmissions';

export const PRIMARY_DATA_SHARE = 'primaryDataShare';

export const LABELS = 'labels';

export const PRODUCT_DESCRIPTION = 'productDescription';

export const PRIMARY_DATA_SHARE_SUFFIX = '%';

export const PRIMARY_DATA_SHARE_PERCENT = 'primaryDataSharePercent';

export const DEFAULT_COMMENT = `
  <ul>
    <li>System boundary: Cradle-to-gate</li>
    <li>Based upon the Partnership for Carbon Transparency (PACT) Pathfinder Framework by the World Business Council for Sustainable Development (WBCSD)</li>
    <li>PCF calculated using CircularTree’s PACT conformant SaaS solution CarbonBlock</li>
  </ul>
  `;
