import { Action, ActionReducer } from '@ngrx/store';
import merge from 'lodash.merge';
import { localStorageSync } from 'ngrx-store-localstorage';

import { State } from '../index';

const INIT_ACTION = '@ngrx/store/init';
const UPDATE_ACTION = '@ngrx/store/update-reducers';

const mergeReducer = (state: State, rehydratedState: State, action: Action): State => {
  if ((action.type === INIT_ACTION || action.type === UPDATE_ACTION) && rehydratedState) {
    state = merge(state, rehydratedState);
  }

  return state;
};

export function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return localStorageSync({
    keys: [
      {
        userPreferences: ['preferredTheme', 'preferredLanguage'],
      },
    ],
    rehydrate: true,
    mergeReducer,
  })(reducer);
}
