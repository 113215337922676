import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SecondaryDataRequest } from '../pages/aux-modals/request-quote/components/request-quote/request-quote.component';
import { RequestQuoteStorage } from '../shared/models/request-quote-storage.interface';

const REQUEST_QUOTE_LOCAL_STORAGE_KEY = 'requestQuote';

@Injectable({
  providedIn: 'root',
})
export class RequestQuoteStorageService {
  requestedProducts$ = new BehaviorSubject<{
    requestQuoteStorage: RequestQuoteStorage;
    lastUpdateProductId: string | null;
    lastRemovedProductId: string | null;
  }>({
    requestQuoteStorage: JSON.parse(localStorage.getItem(REQUEST_QUOTE_LOCAL_STORAGE_KEY) ?? '{}'),
    lastUpdateProductId: null,
    lastRemovedProductId: null,
  });
  requestableComponentForSecondaryData$ = new BehaviorSubject<SecondaryDataRequest[] | null>(null);

  setRequestedProductToStorage(productId: string, componentIds: string[]) {
    const existingIds: RequestQuoteStorage = JSON.parse(
      localStorage.getItem(REQUEST_QUOTE_LOCAL_STORAGE_KEY) ?? '{}',
    );
    if (existingIds[productId]) {
      existingIds[productId] = [...existingIds[productId], ...componentIds];
    } else {
      existingIds[productId] = componentIds;
    }
    localStorage.setItem(REQUEST_QUOTE_LOCAL_STORAGE_KEY, JSON.stringify(existingIds));
    this.requestedProducts$.next({
      requestQuoteStorage: existingIds,
      lastUpdateProductId: productId,
      lastRemovedProductId: null,
    });
  }

  removeProductComponents(productId: string, componentIds: string) {
    const existingIds: RequestQuoteStorage = JSON.parse(
      localStorage.getItem(REQUEST_QUOTE_LOCAL_STORAGE_KEY) ?? '{}',
    );
    if (existingIds[productId]) {
      const newCompIds = existingIds[productId].filter(
        (compId: string) => !componentIds.includes(compId),
      );
      if (newCompIds.length) {
        existingIds[productId] = newCompIds;
      } else {
        delete existingIds[productId];
      }
      localStorage.setItem(REQUEST_QUOTE_LOCAL_STORAGE_KEY, JSON.stringify(existingIds));
      this.requestedProducts$.next({
        requestQuoteStorage: existingIds,
        lastRemovedProductId: productId,
        lastUpdateProductId: null,
      });
    }
  }
}
