export interface UserProfileState {
  firstName: string;
  lastName: string;
  email: string;
}

export const initialState: UserProfileState = {
  firstName: '',
  lastName: '',
  email: '',
};
