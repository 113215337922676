import { createReducer, on } from '@ngrx/store';

import {
  loadBomTemplateLink,
  loadBomTemplateLinkFailure,
  loadBomTemplateLinkSuccess,
  loadProducts,
  loadProductsFailure,
  loadProductsSuccess,
  setProductAdding,
  setSelectedProductId,
} from './actions/products.actions';
import { ProductsState, initialState, productsEntityAdapter } from './models/products.state';

export const productsReducer = createReducer(
  initialState,

  on(
    loadProducts,
    (state): ProductsState => ({
      ...state,
      productsLoading: true,
    }),
  ),

  on(
    setProductAdding,
    (state, { isAdding }): ProductsState => ({
      ...state,
      productAdding: isAdding,
    }),
  ),

  on(
    loadProductsSuccess,
    (state, { products }): ProductsState => ({
      ...state,
      products: productsEntityAdapter.setAll(products, state.products),
      productsLoading: false,
    }),
  ),

  on(
    loadProductsFailure,
    (state): ProductsState => ({
      ...state,
      productsLoading: false,
    }),
  ),

  on(
    loadBomTemplateLink,
    (state): ProductsState => ({
      ...state,
      bomTemplateLinkLoading: true,
    }),
  ),

  on(
    loadBomTemplateLinkSuccess,
    (state, { url }): ProductsState => ({
      ...state,
      bomTemplateLink: { url },
      bomTemplateLinkLoading: false,
    }),
  ),

  on(
    loadBomTemplateLinkFailure,
    (state): ProductsState => ({
      ...state,
      bomTemplateLinkLoading: false,
    }),
  ),

  on(
    setSelectedProductId,
    (state, { id }): ProductsState => ({
      ...state,
      selectedProductId: id,
    }),
  ),
);
