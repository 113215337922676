import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, map, switchMap, tap } from 'rxjs';

@Injectable()
export class LoadingService {
  private loadingCount$$ = new BehaviorSubject(0);

  isLoading$: Observable<boolean> = this.loadingCount$$.pipe(map((count) => count > 0));

  incrementLoading() {
    this.loadingCount$$.next(this.loadingCount$$.value + 1);
  }

  decrementLoading() {
    this.loadingCount$$.next(this.loadingCount$$.value - 1);
  }

  loadingSwitchMap<T, R>(createRequestFunction: (value: T) => Observable<R>) {
    return (source: Observable<T>) => {
      return source.pipe(
        tap(() => this.incrementLoading()),
        switchMap((val) =>
          createRequestFunction(val).pipe(finalize(() => this.decrementLoading())),
        ),
      );
    };
  }
}
