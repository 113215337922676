import { Pipe, PipeTransform } from '@angular/core';

/**
 * Created pipe for locale number to consistently present numbers in locale format across the entire application.
 */
@Pipe({
  name: 'localeNumber',
})
export class LocaleNumberPipe implements PipeTransform {
  /**
   * Introduced two attributes that specify the maximum and minimum fraction digits.
   */
  transform(value: any, maxFractionDigits = 10, minFractionDigits = 0): string {
    if (value === null || value === undefined) return '';

    const locale = navigator.language ? navigator.language : 'en-US';

    const formattedNumber = new Intl.NumberFormat(locale, {
      maximumFractionDigits: maxFractionDigits,
      minimumFractionDigits: minFractionDigits,
    }).format(value);
    return formattedNumber;
  }
}
