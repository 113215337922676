import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MessageModule } from 'primeng/message';
import { CopyClipboardDirective } from 'src/app/shared/utilities/common-util/copy-text.directive';

import { LoadingService } from '../../services';
import { SharedModule } from '../../shared/shared.module';
import { RequestsService } from '../requests/services/requests.service';
import { SignUpComponent } from './components/sign-up.component';
import { SignUpRoutingModule } from './sign-up-routing.module';

@NgModule({
  declarations: [SignUpComponent, CopyClipboardDirective],
  imports: [SignUpRoutingModule, SharedModule, TranslateModule.forChild(), MessageModule],
  providers: [RequestsService, LoadingService],
})
export class SignUpModule {}
