import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import { Link } from 'src/app/shared/models/create-product.interfaces';

import { CarbonProductOverviewGridItem } from '../../../shared/models/products-overview.interfaces';

export interface ProductsState {
  products: EntityState<CarbonProductOverviewGridItem>;
  selectedProductId: string | null;
  productsLoading: boolean;
  productAdding: boolean;
  bomTemplateLink: Link | null;
  bomTemplateLinkLoading: boolean;
}

export const productsEntityAdapter: EntityAdapter<CarbonProductOverviewGridItem> =
  createEntityAdapter<CarbonProductOverviewGridItem>({
    sortComparer: false,
  });

export const initialState: ProductsState = {
  products: productsEntityAdapter.getInitialState({}),
  selectedProductId: null,
  productsLoading: false,
  productAdding: false,
  bomTemplateLink: null,
  bomTemplateLinkLoading: false,
};
