import { createReducer, on } from '@ngrx/store';

import { ComponentPcfStatus } from '../../shared/models/product-component.interfaces';
import {
  loadComponents,
  loadComponentsFailure,
  loadComponentsSuccess,
  requestQuotesByCompIds,
  requestQuotesFailure,
  requestQuotesSuccess,
  setRequestQuoteComponents,
  setSelectedComponentId,
  updateComponentById,
  updateComponentFailure,
  updateComponentSuccess,
} from './actions/components.actions';
import { ComponentsState, initialState } from './models/components.state';

export const componentsReducer = createReducer(
  initialState,
  on(
    loadComponents,
    (state): ComponentsState => ({
      ...state,
      componentLoading: true,
    }),
  ),

  on(
    updateComponentById,
    (state): ComponentsState => ({
      ...state,
      componentUpdating: true,
    }),
  ),

  on(
    loadComponentsSuccess,
    (state, { pcfRequestComponent }): ComponentsState => ({
      ...state,
      pcfRequestComponent,
      componentLoading: false,
    }),
  ),

  on(
    loadComponentsFailure,
    (state): ComponentsState => ({
      ...state,
      componentLoading: false,
    }),
  ),

  on(
    setSelectedComponentId,
    (state, { id }): ComponentsState => ({
      ...state,
      selectedComponentId: id,
    }),
  ),

  on(
    updateComponentSuccess,
    (state, { id }): ComponentsState => ({
      ...state,
      componentUpdating: false,
      pcfRequestComponent: state.pcfRequestComponent
        ? {
            ...state.pcfRequestComponent,
            id,
            requestStatus: ComponentPcfStatus.RequestSent,
          }
        : null,
    }),
  ),

  on(
    updateComponentFailure,
    (state): ComponentsState => ({
      ...state,
      componentUpdating: false,
    }),
  ),

  on(
    requestQuotesByCompIds,
    (state): ComponentsState => ({
      ...state,
      componentUpdating: true,
    }),
  ),

  on(
    setRequestQuoteComponents,
    (state, { components }): ComponentsState => ({
      ...state,
      requestQuoteComponents: components,
    }),
  ),

  on(
    requestQuotesSuccess,
    (state): ComponentsState => ({
      ...state,
      componentUpdating: false,
    }),
  ),

  on(
    requestQuotesFailure,
    (state): ComponentsState => ({
      ...state,
      componentUpdating: false,
    }),
  ),
);
