import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ControlErrorsContainerDirective } from './control-errors/control-errors-container.directive';
import { ControlErrorsDirective } from './control-errors/control-errors.directive';
import { FormSubmitDirective } from './control-errors/form-submit.directive';

@NgModule({
  declarations: [ControlErrorsDirective, ControlErrorsContainerDirective, FormSubmitDirective],
  imports: [CommonModule],
  exports: [ControlErrorsDirective, ControlErrorsContainerDirective, FormSubmitDirective],
})
export class CommonDirectivesModule {}
