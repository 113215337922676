import { CarbonComponentDetails, DataSourceType } from './component-details.interfaces';

export interface CarbonProductComponent extends CarbonComponentDetails {
  parentComponentId?: string;
  children?: CarbonProductComponent[];
  depth: number;
}

export enum SecondaryRequestStatus {
  Pending = 'Pending',
  Completed = 'Completed',
}

export interface ComponentQuantity {
  value: number;
  unit: string;
}

export enum ComponentPcfStatus {
  RequestNotSent = 'request-not-sent',
  RequestSent = 'request-sent',
  ResponseNoData = 'response-no-data',
  ResponseGateToGate = 'response-gate-to-gate',
  ResponseCradleToGate = 'response-cradle-to-gate',
}

// Fix for inconsistency issues with requestStatus
export enum PcfRequestStatus {
  Pending = 'Pending',
  Completed = 'Completed',
}

export interface SelectedComponent {
  id: string;
  requestStatus?: ComponentPcfStatus;
  pcfRequestStatus?: PcfRequestStatus;
  secondaryDataStatus?: SecondaryRequestStatus;
  dataSourceType?: DataSourceType;
}
