import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { PrimeNGConfig } from 'primeng/api';
import { take } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

import { environment } from '../environments/environment';
import { CustomErrorService } from './core/services/custom-error.service';
import { preferredLanguageChange } from './store/user-preferences/actions/user-preferences.actions';
import { setUserProfile } from './store/user-profile/actions/user-profile.actions';

const USER_PREFERENCES_LOCAL_STORAGE_KEY = 'userPreferences';
const SCOPE = ['openid', 'profile', 'email'];
const RESPONSE_TYPE = 'code';
const AUTHENTICATION_FLOW_TYPE = 'USER_PASSWORD_AUTH';

@Component({
  selector: 'carbon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private hubListener: any;

  constructor(
    private primeConfig: PrimeNGConfig,
    private translate: TranslateService,
    private store: Store,
    private customErrorService: CustomErrorService,
  ) {}

  private static setupAmplify() {
    Amplify.configure({
      Auth: environment.cognito.auth,
      oauth: {
        domain: `${environment.userPoolDomainName}.auth.eu-central-1.amazoncognito.com`,
        scope: SCOPE,
        redirectSignIn: `${window.location.origin}/`,
        redirectSignOut: `${window.location.origin}/`,
        responseType: RESPONSE_TYPE,
      },
      API: environment.api,
      authenticationFlowType: AUTHENTICATION_FLOW_TYPE,
    });
  }

  ngOnInit() {
    AppComponent.setupAmplify();
    this.setupTranslation();
    this.setupPrimeNG();
    this.setUserProfile();
    // Set up the Hub listener to capture Amplify auth events
    this.hubListener = Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn_failure') {
        // Decode and set error message in the service
        const errorMessage = decodeURIComponent(payload.data?.message || '').replace(/\+/g, ' ');
        if (errorMessage.includes('Invalid SAML')) {
          this.customErrorService.setError(errorMessage);
        }
      }
    });
  }

  ngOnDestroy() {
    // Remove Hub listener when the component is destroyed
    Hub.remove('auth', this.hubListener);
  }

  private setupTranslation() {
    this.translate.addLangs(environment.translate.supportedLanguages);
    this.translate.setDefaultLang(environment.translate.defaultLanguage);

    const browserLanguage = this.translate.getBrowserLang();
    let preferredLanguage;
    let userPreferredLanguage;
    const { defaultLanguage, supportedLanguages } = environment.translate;

    try {
      const userPreferences = JSON.parse(
        localStorage.getItem(USER_PREFERENCES_LOCAL_STORAGE_KEY) || '',
      );
      userPreferredLanguage = userPreferences.preferredLanguage;
    } catch {
      if (userPreferredLanguage && supportedLanguages.includes(userPreferredLanguage)) {
        preferredLanguage = userPreferredLanguage;
      } else if (browserLanguage && supportedLanguages.includes(browserLanguage)) {
        preferredLanguage = browserLanguage;
      } else {
        preferredLanguage = defaultLanguage;
      }
    }

    this.translate.use(preferredLanguage);
    this.store.dispatch(preferredLanguageChange({ languageCode: this.translate.currentLang }));
  }

  private setupPrimeNG() {
    this.primeConfig.ripple = true;
  }

  private setUserProfile() {
    fromPromise(Auth.currentSession())
      .pipe(take(1))
      .subscribe((session) => {
        const { email, given_name, family_name } = session.getIdToken().payload;
        const profile = {
          firstName: given_name || 'First name',
          lastName: family_name || 'Last name',
          email,
        };

        this.store.dispatch(setUserProfile({ profile }));
      });
  }
}
