import { createAction, props } from '@ngrx/store';

import { CarbonProductDetailsResponse } from '../../../shared/models/product-details.interface';
import { SharePcfPayload } from '../../../shared/models/share-pcf-payload.interface';

export const loadProduct = createAction('[Share PCF] Load Product', props<{ productId: string }>());
export const loadProductSuccess = createAction(
  '[Share PCF] load Product Success',
  props<{ product: CarbonProductDetailsResponse }>(),
);
export const loadProductFailure = createAction('[Share PCF] Load Product Failure');

export const setSharePcfPayload = createAction(
  '[Share PCF] Set Share Pcf Payload',
  props<{ sharePcfPayload: SharePcfPayload }>(),
);

export const resetSharePcf = createAction('[Share PCF] Reset Share Pcf');
