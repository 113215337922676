import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { map, switchMap } from 'rxjs';

import { ApiToStoreCallsService } from '../../../services';
import { loadProduct, loadProductSuccess } from '../actions/share-pcf.actions';

@Injectable()
export class SharePcfEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private apiService: ApiToStoreCallsService,
    private message: MessageService,
    private translate: TranslateService,
  ) {}

  loadProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadProduct),
      switchMap(({ productId }) => this.apiService.getProduct(productId)),
      map((response) => loadProductSuccess({ product: response })),
    );
  });
}
