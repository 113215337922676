import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  Notification,
  NotificationEventType,
} from '../../../../../shared/models/notification.interface';

@Component({
  selector: 'carbon-notification-message',
  templateUrl: './notification-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsMessageComponent {
  @Input()
  notification?: Notification;

  constructor(private translate: TranslateService) {}

  getNotificationMessage(notification: Notification) {
    switch (notification.eventType) {
      case NotificationEventType.AnswerForPCFRequest:
        return this.translate.instant('Home.Notifications.AnswerForPCFRequest', {
          componentName: notification.componentName,
        });
      case NotificationEventType.RequestForPCF:
        return this.translate.instant('Home.Notifications.RequestForPCF', {
          componentName: notification.componentName,
        });
      case NotificationEventType.UpdatedAnswerForPCFRequest:
        return this.translate.instant('Home.Notifications.UpdatedAnswerForPCFRequest', {
          componentName: notification.componentName,
        });
      case NotificationEventType.UpdatedComponentValuesAvailable:
        return this.translate.instant('Home.Notifications.UpdatedComponentValuesAvailable', {
          componentName: notification.componentName,
        });
      case NotificationEventType.BOMUploaded:
        return this.translate.instant('Home.Notifications.BOMUploaded', {
          productName: notification.productName,
        });
      case NotificationEventType.BomUploadError:
        return notification.downloadLink
          ? this.translate.instant('Home.Notifications.BomManualUploadFailure', {
              productName: notification.productName,
              bomDownloadUrl: notification.downloadLink,
            })
          : this.translate.instant('Home.Notifications.BomManualUploadSuccess', {
              productName: notification.productName,
            });
      default:
        return this.translate.instant('RequestQuote.RequestQuoteSuccessMessage', {
          componentName: notification.componentName,
        });
    }
  }
}
