import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { BlockableUI } from 'primeng/api';

@Component({
  selector: 'carbon-loading-blockable',
  templateUrl: './loading-blockable.component.html',
  styleUrls: ['./loading-blockable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBlockableComponent implements BlockableUI {
  constructor(private element: ElementRef) {}

  getBlockableElement(): HTMLElement {
    return this.element.nativeElement;
  }
}
