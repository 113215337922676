import { createAction, props } from '@ngrx/store';

export const auxModalClickNext = createAction('[AUX Modal] Click On Next');
export const auxModalClickBack = createAction('[AUX Modal] Click On Back');
export const auxModalClickCancelOrClose = createAction('[AUX Modal] Click On Cancel Or Close');
export const auxModalRefreshAfterClose = createAction(
  '[AUX Modal] Refresh After Close',
  props<{
    showConfirmation: boolean;
    lastAddedProductId?: string;
    bomImportFailed?: boolean;
    failedProductName?: string;
    bomDownloadUrl?: string;
  }>(),
);

export const auxModalClickSaveShare = createAction(
  '[AUX Modal] Click On Save And/Or Share',
  props<{ isShare: boolean }>(),
);

export const setModalHeightWidth = createAction(
  '[AUX Modal] Set Aux Modal Height Width',
  props<{ width: string; height: string }>(),
);

export const resetModalHeightWidth = createAction('[AUX Modal] Reset Aux Modal Height Width');
