import { createReducer, on } from '@ngrx/store';

import { setUserProfile } from './actions/user-profile.actions';
import { UserProfileState, initialState } from './models/user-profile.state';

export const userProfileReducer = createReducer(
  initialState,

  on(
    setUserProfile,
    (state, { profile }): UserProfileState => ({
      ...state,
      ...profile,
    }),
  ),
);
