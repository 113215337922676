import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description When we search for some entities in a search box, it will highlight matching texts in the search result
 */
@Pipe({
  name: 'highlightText',
})
export class HighlightTextPipe implements PipeTransform {
  transform(value: string, searchText: string): string {
    if (!searchText) {
      return value;
    }
    const escapedText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const result = new RegExp(escapedText, 'gi');
    return value.replace(result, '<strong>$&</strong>');
  }
}
