<p-toast position="top-right" key="c" (onClose)="onReject()" [baseZIndex]="5000">
  <ng-template let-data pTemplate="data">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="flex flex-row align-items-center">
        <div class="flex flex-column" style="flex-grow: 1">
          <div class="flex align-items-center justify-content-between mb-1">
            <span class="fs-small font-bold"
              >{{
                data.data.eventType === NotificationEventType.BOMUploaded
                  ? ('Global.CarbonBlock' | translate)
                  : data.data.tenantName
              }}
            </span>
          </div>
          <carbon-notification-message [notification]="data.data"></carbon-notification-message>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<div class="layout-topbar shadow-3">
  <div class="layout-topbar-left">
    <div class="layout-topbar-logo pr-0">
      <img src="assets/images/carbon-logo.svg" alt="CarbonBlockLogo" />
    </div>
    <a pRipple class="layout-topbar-mobile-button" (click)="toggleMobileMenu()">
      <i class="pi pi-ellipsis-v fs-large"></i>
    </a>
  </div>

  <div class="layout-topbar-right pl-0" [ngClass]="{ 'layout-topbar-mobile-active': showMobileMenu }">
    <div class="layout-topbar-actions-left">
      <!-- Hidden for demo purpose -->
      <!-- <a
        [routerLink]="['/home']"
        routerLinkActive="action-active"
        class="layout-topbar-action flex align-items-center gap-1"
      >
        <i class="cb cb-icon-home cb-topbar"></i>
        <span>{{ 'Home.DashboardLink' | translate }}</span>
      </a> -->

      <a
        [routerLink]="['/products']"
        routerLinkActive="action-active"
        class="layout-topbar-action flex align-items-center gap-1"
      >
        <i class="cb cb-icon-products-filled cb-topbar"></i>
        <span>{{ 'Home.ProductsLink' | translate }}</span>
      </a>

      <a
        [routerLink]="['/suppliers']"
        routerLinkActive="action-active"
        class="layout-topbar-action flex align-items-center gap-1"
      >
        <i class="cb cb-icon-suppliers cb-topbar"></i>
        <span>{{ 'Home.SuppliersLink' | translate }}</span>
      </a>

      <a
        [routerLink]="['/requests', 'overview']"
        routerLinkActive="action-active"
        class="layout-topbar-action flex align-items-center gap-1"
      >
        <i class="cb cb-icon-requests cb-topbar"></i>
        <span>{{ 'Home.RequestsLink' | translate }}</span>
      </a>
    </div>
    <div class="layout-topbar-actions-right">
      <ul class="layout-topbar-items">
        <li class="layout-topbar-item notifications">
          <a
            href="javascript:void(0)"
            class="layout-topbar-action rounded-circle"
            pRipple
            pTooltip="{{ 'Home.NotificationsTooltip' | translate }}"
            tooltipPosition="left"
            (click)="toggleMenu('notifications')"
          >
            <span class="icon-overlay">
              <i class="cb cb-icon-notification cb-white"></i>
              <span class="icon-dot" *ngIf="newNotification"></span>
            </span>
          </a>
          <carbon-notifications-menu *ngIf="notificationPanelActive"></carbon-notifications-menu>
        </li>
        <li class="layout-topbar-item">
          <a
            class="layout-topbar-action rounded-circle link-cursor pl-2-5"
            pRipple
            pTooltip="{{ 'Home.AccountMenuTooltip' | translate }}"
            tooltipPosition="left"
            (click)="toggleMenu('account')"
          >
            <span class="icon-overlay">
              <p-avatar
                class="user-avatar"
                label="{{ profile && profile.firstName ? profile.firstName[0] : '' }}{{
                  profile && profile.lastName ? profile.lastName[0] : ''
                }}"
                shape="circle"
              ></p-avatar>
            </span>
          </a>
          <carbon-user-menu *ngIf="userPanelActive"></carbon-user-menu>
        </li>
      </ul>
    </div>
  </div>
</div>
