import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

/**
 * Directive that adds copy-to-clipboard functionality to any element.
 * When the element with this directive is clicked, it copies the provided text
 * to the clipboard and briefly changes the icon to indicate success.
 *
 * Usage:
 * <any-element [carbonCopyText]="'Text to copy'">Click to copy</any-element>
 *
 * The directive expects an 'icon-copy.svg' as the default icon and 'copied.gif'
 * for indicating successful copying, located in '/assets/icons/'.
 *
 * Attributes:
 * @param {string} carbonCopyText - The text that will be copied to the clipboard when the element is clicked.
 */

@Directive({
  selector: '[carbonCopyText]',
})
export class CopyClipboardDirective implements OnInit {
  @Input('carbonCopyText') textToCopy!: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const icon = this.renderer.createElement('img');
    this.renderer.setAttribute(icon, 'src', '/assets/icons/icon-copy.svg');
    this.renderer.addClass(icon, 'cb');
    this.renderer.appendChild(this.el.nativeElement, icon);

    this.renderer.listen(icon, 'click', (event: Event) => {
      event.preventDefault();
      this.copyText();
    });
  }

  private copyText(): void {
    if (navigator.clipboard && this.textToCopy) {
      navigator.clipboard.writeText(this.textToCopy).then(() => this.showSuccessIndicator());
    }
  }

  private showSuccessIndicator(): void {
    const icon = this.el.nativeElement.querySelector('img');
    this.renderer.setAttribute(icon, 'src', '/assets/icons/copied.gif');
    setTimeout(() => this.renderer.setAttribute(icon, 'src', '/assets/icons/icon-copy.svg'), 2500);
  }
}
