import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'carbon-image-with-loading',
  templateUrl: './image-with-loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageWithLoadingComponent {
  isLoading = true;

  @Input()
  imageSrc = 'assets/images/no-image.png';

  @Input()
  alt = '';

  @Input()
  height = '15.625rem';

  @Input()
  width = '15.625rem';

  hideLoader(): void {
    this.isLoading = false;
  }
}
