import { PcfReadiness } from 'src/app/shared/models/pcf-readiness.enum';
import { InboundRequestStatus } from 'src/app/shared/models/request-status.enum';

import { CarbonProductInboundRequest } from '../../../shared/models/product-inbound-request.domain';

const initialProduct: CarbonProductInboundRequest = {
  id: '',
  created: '',
  modified: '',
  incomingTenant: {
    companyName: '',
    tenantId: '',
    tenantName: '',
  },
  message: '',
  status: InboundRequestStatus.Pending,
  incomingSupplierName: '',
  incomingRequestId: '',
  productName: '',
  productId: '',
  tenantId: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmailAddress: '',
  componentDetails: {
    componentName: '',
    id: '',
    componentIdentifier: {
      idType: '',
      idValue: '',
    },
  },
  readiness: PcfReadiness.CompanyLevelOnly,
  scope: [],
};

export interface ProductInboundRequestState {
  product: CarbonProductInboundRequest;
  productLoading: boolean;
  inboundRequests: CarbonProductInboundRequest[];
  requestsLoading: boolean;
}

export const initialState: ProductInboundRequestState = {
  product: initialProduct,
  inboundRequests: [],
  productLoading: false,
  requestsLoading: false,
};
