import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _breadcrumbMenuItems$$ = new BehaviorSubject<MenuItem[]>([]);

  breadcrumbMenuItems$ = this._breadcrumbMenuItems$$.asObservable();

  setBreadcrumbs(menuItems: MenuItem[]): void {
    this._breadcrumbMenuItems$$.next(menuItems);
  }
}
