import { createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';

import { CarbonProductDetailsResponse } from '../../../shared/models/product-details.interface';
import { SharePcfPayload } from '../../../shared/models/share-pcf-payload.interface';

export interface SharePcfState {
  product: CarbonProductDetailsResponse | null;
  productLoading: boolean;
  sharePcfPayload: SharePcfPayload | null;
}

export const sharePcfAdapter: EntityAdapter<CarbonProductDetailsResponse> =
  createEntityAdapter<CarbonProductDetailsResponse>({
    sortComparer: false,
  });

export const initialState: SharePcfState = {
  product: null,
  productLoading: false,
  sharePcfPayload: null,
};
