<ul class="help-menu layout-topbar-action-panel shadow-2" [@topbarActionPanelAnimation]="'visible'">
  <li class="layout-topbar-action-item">
    <div class="flex align-items-center gap-2 action" (click)="showAbout()">
      <i class="cb cb-icon-info cb-interface"></i>
      <span>{{ 'Home.HelpMenuLinks.About' | translate }}</span>
    </div>
  </li>
  <li class="layout-topbar-action-item">
    <div class="flex align-items-center gap-2 action" (click)="mailTo()">
      <i class="cb cb-icon-contact cb-interface"></i>
      <span>{{ 'Home.HelpMenuLinks.Contact' | translate }}</span>
    </div>
  </li>
</ul>
