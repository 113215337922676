import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './core/aux-modals/about/about.component';
import { AuxModalViewComponent } from './core/containers/aux-modal-view-container/aux-modal-view.component';
import { PreloadModulesStrategy } from './core/strategies/preload-modules.strategy';

const routes: Routes = [
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./pages/suppliers/suppliers.module').then((m) => m.SuppliersModule),
  },
  {
    path: 'requests',
    loadChildren: () => import('./pages/requests/requests.module').then((m) => m.RequestsModule),
  },
  {
    path: 'modal',
    outlet: 'aux',
    component: AuxModalViewComponent,
    children: [
      {
        path: 'add-product',
        loadChildren: () =>
          import('./pages/aux-modals/add-product-modal/add-product-modal.module').then(
            (m) => m.AddProductModalModule,
          ),
      },
      {
        path: 'request-pcf',
        loadChildren: () =>
          import('./pages/aux-modals/request-pcf/request-pcf.module').then(
            (m) => m.RequestPcfModule,
          ),
      },
      {
        path: 'request-quote',
        loadChildren: () =>
          import('./pages/aux-modals/request-quote/request-quote.module').then(
            (m) => m.RequestQuoteModule,
          ),
      },
      {
        path: 'share-pcf/:productId',
        loadChildren: () =>
          import('./pages/aux-modals/share-pcf/share-pcf.module').then((m) => m.SharePcfModule),
      },
      {
        path: 'about',
        component: AboutComponent,
      },
    ],
  },

  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadModulesStrategy,
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
  providers: [PreloadModulesStrategy],
})
export class AppRoutingModule {}
