import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ERRORS_CONFIG, ErrorConfig } from '../directives/control-errors/control-errors.token';

@Pipe({
  name: 'getError',
})
export class GetErrorPipe implements PipeTransform {
  constructor(
    @Inject(ERRORS_CONFIG) private errors: ErrorConfig,
    private translate: TranslateService,
  ) {}

  transform(value: ErrorConfig | null | undefined, customErrors: ErrorConfig = {}): string | null {
    if (value) {
      const firstErrorKey = Object.keys(value)[0];
      const errorFunctionFromConfig = customErrors[firstErrorKey] || this.errors[firstErrorKey];
      const errorText = this.translate.instant(errorFunctionFromConfig(value[firstErrorKey]));
      return errorText;
    }
    return null;
  }
}
