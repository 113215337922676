import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// TODO: need to think about it how to provide something else instead of any
/* eslint-disable */
type GConstructor<T = any> = new (...args: any[]) => T;

export function untilDestroy<BaseType extends GConstructor>(BaseClass: BaseType) {
  return class extends BaseClass implements OnDestroy {
    protected destroy$$ = new Subject<void>();

    constructor(...args: any[]) {
      super(args);
    }

    ngOnDestroy() {
      this.destroy$$.next();
      this.destroy$$.complete();
    }
  };
}
