import { InjectionToken } from '@angular/core';

export interface ErrorConfig {
  [key: string]: (error?: unknown) => string;
}
export const defaultErrors: ErrorConfig = {
  required: () => 'Errors.Required',
  email: () => 'Errors.Email',
  productIdEanJan: () => 'Errors.ProductIdEanOrJan',
  productIdUpc: () => 'Errors.ProductIdUpc',
  productIdOther: () => 'Errors.ProductIdOther',
  dateRange: () => 'Errors.DateRangeRequired',
  pattern: () => 'Errors.Email',
};

export const ERRORS_CONFIG = new InjectionToken('ERRORS_CONFIG', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
