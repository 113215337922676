import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
  auxModalClickCancelOrClose,
  setModalHeightWidth,
} from '../../../store/event-bus/actions/aux-modal.actions';

@Component({
  selector: 'carbon-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent implements OnInit {
  year = new Date().getFullYear();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(setModalHeightWidth({ width: '350px', height: 'auto' }));
  }

  onCancelClick() {
    this.store.dispatch(auxModalClickCancelOrClose());
  }
}
