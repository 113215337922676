import { createAction, props } from '@ngrx/store';
import { CarbonSuppliersOverviewGridItem } from 'src/app/shared/models/suppliers/suppliers-overview.interfaces';

export const loadSuppliers = createAction('[suppliers] Load suppliers');

export const loadSuppliersSuccess = createAction(
  '[suppliers] Load suppliers Success',
  props<{ suppliers: CarbonSuppliersOverviewGridItem[] }>(),
);

export const loadSuppliersFailure = createAction('[suppliers] Load suppliers Failure');
