import { createReducer, on } from '@ngrx/store';

import * as userPreferencesActions from './actions/user-preferences.actions';
import { UserPreferencesState, initialState } from './models/user-preferences.state';

export const userPreferencesReducer = createReducer(
  initialState,

  on(userPreferencesActions.preferredThemeChange, (state, { theme }): UserPreferencesState => {
    return {
      ...state,
      preferredTheme: theme,
    };
  }),

  on(
    userPreferencesActions.preferredLanguageChange,
    (state, { languageCode }): UserPreferencesState => {
      return {
        ...state,
        preferredLanguage: languageCode,
      };
    },
  ),
);
