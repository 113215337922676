import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserProfileState } from '../models/user-profile.state';

export const userProfileFeatureKey = 'userProfile';

export const selectUserProfileState =
  createFeatureSelector<UserProfileState>(userProfileFeatureKey);

export const selectUserName = createSelector(
  selectUserProfileState,
  (state) => `${state.firstName} ${state.lastName}`,
);
