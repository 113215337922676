import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from '../../environments/environment';
import { componentsReducer } from './components/components.reducer';
import { ComponentsState } from './components/models/components.state';
import { auxModalReducer } from './event-bus/aux-modal.reducer';
import { AuxModalState } from './event-bus/models/aux-modal.state';
import { localStorageSyncReducer } from './meta-reducers/local-storage.reducer';
import { ProductInboundRequestState } from './product-inbound-request/models/product-inbound-request.state';
import { productInboundRequestReducer } from './product-inbound-request/product-inbound-request.reducer';
import { ProductsState } from './products/models/products.state';
import { productsReducer } from './products/products.reducer';
import { RouterState } from './router/models/router.state';
import { SharePcfState } from './share-pcf/models/share-pcf.state';
import { sharePcfReducer } from './share-pcf/share-pcf.reducer';
import { SuppliersState } from './suppliers/models/suppliers.state';
import { suppliersReducer } from './suppliers/suppliers.reducer';
import { UserPreferencesState } from './user-preferences/models/user-preferences.state';
import { userPreferencesReducer } from './user-preferences/user-preferences.reducer';
import { UserProfileState } from './user-profile/models/user-profile.state';
import { userProfileReducer } from './user-profile/user-profile.reducer';

export interface State {
  userProfile: UserProfileState;
  products: ProductsState;
  suppliers: SuppliersState;
  components: ComponentsState;
  router: RouterState;
  userPreferences: UserPreferencesState;
  productInboundRequest: ProductInboundRequestState;
  auxModal: AuxModalState;
  sharePcf: SharePcfState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  userProfile: userProfileReducer,
  userPreferences: userPreferencesReducer,
  products: productsReducer,
  suppliers: suppliersReducer,
  components: componentsReducer,
  productInboundRequest: productInboundRequestReducer,
  auxModal: auxModalReducer,
  sharePcf: sharePcfReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [localStorageSyncReducer]
  : [localStorageSyncReducer];
