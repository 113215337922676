import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { LoadingService } from '../../../../services/loading.service';

@Component({
  selector: 'carbon-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingContainerComponent {
  @Input()
  blockableClasses: string;

  isLoading$: Observable<boolean> = this.loadingService.isLoading$;

  constructor(private loadingService: LoadingService) {
    this.blockableClasses = '';
  }
}
