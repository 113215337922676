<ng-container *ngIf="showConfirmation$ | async"></ng-container>
<p-confirmDialog position="top-right" [style]="{ width: '26vw' }"> </p-confirmDialog>
<p-toast></p-toast>
<div class="layout-wrapper layout-topbar-carbon">
  <carbon-top-bar></carbon-top-bar>
  <div class="layout-main">
    <div class="layout-content" [class.layout-padding]="isApplyPadding$ | async">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
