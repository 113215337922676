<carbon-modal-view-container [showCloseBtn]="false" [showHeader]="false" [showFooter]="false">
  <div class="flex justify-content-center align-items-center pt-3">
    <div class="flex justify-content-end pe-1">
      <img src="assets/images/carbon_login_logo.svg" alt="CarbonBlockLogo" width="140px" />
    </div>
    <div class="flex justify-content-end pe-1 closeIcon">
      <i class="cb cb-icon-close cb-interface cursor-pointer pull-right" (click)="onCancelClick()"> </i>
    </div>
  </div>
  <div class="flex justify-content-center align-items-center pt-3">
    <img src="assets/images/carbon-logo-dark.svg" alt="CarbonBlockLogo" width="90px" />
  </div>
  <div class="flex justify-content-center align-items-center pt-2">
    <span class="version"> {{ 'Home.About.version' | translate }}</span>
  </div>
  <div class="flex justify-content-center align-items-center pt-3">
    <span class="copyright">
      {{ 'Home.About.copyright' | translate: { year } }}
    </span>
  </div>
  <div class="flex justify-content-center align-items-center pt-6">
    <span class="terms link-cursor">
      {{ 'Home.About.terms' | translate }}
    </span>
  </div>
</carbon-modal-view-container>
