import { createReducer, on } from '@ngrx/store';

import {
  loadInboundRequests,
  loadInboundRequestsFailure,
  loadInboundRequestsSuccess,
  resetProductInboundRequest,
  setProductInboundRequest,
  setProductInboundRequestFailure,
  setProductInboundRequestSuccess,
} from './actions/product-inbound-request.actions';
import { ProductInboundRequestState, initialState } from './models/product-inbound-request.state';

export const productInboundRequestReducer = createReducer(
  initialState,

  on(
    loadInboundRequests,
    (state): ProductInboundRequestState => ({
      ...state,
      requestsLoading: true,
    }),
  ),

  on(
    loadInboundRequestsSuccess,
    (state, { inboundRequests }): ProductInboundRequestState => ({
      ...state,
      inboundRequests,
      requestsLoading: false,
    }),
  ),

  on(
    loadInboundRequestsFailure,
    (state): ProductInboundRequestState => ({
      ...state,
      requestsLoading: false,
    }),
  ),

  on(
    setProductInboundRequest,
    (state): ProductInboundRequestState => ({
      ...state,
      productLoading: true,
    }),
  ),

  on(
    setProductInboundRequestSuccess,
    (state, { product }): ProductInboundRequestState => ({
      ...state,
      product,
      productLoading: false,
    }),
  ),

  on(
    setProductInboundRequestFailure,
    (state): ProductInboundRequestState => ({
      ...state,
      productLoading: false,
    }),
  ),

  on(
    resetProductInboundRequest,
    (state): ProductInboundRequestState => ({
      ...state,
      product: initialState.product,
    }),
  ),
);
