import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { RequestsService } from '../../../pages/requests/services/requests.service';
import { ApiToStoreCallsService } from '../../../services';
import {
  loadInboundRequests,
  loadInboundRequestsFailure,
  loadInboundRequestsSuccess,
  setProductInboundRequest,
  setProductInboundRequestFailure,
  setProductInboundRequestSuccess,
} from '../actions/product-inbound-request.actions';

@Injectable()
export class ProductInboundEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private apiService: ApiToStoreCallsService,
    private requestsService: RequestsService,
  ) {}

  loadInboundRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadInboundRequests), // Listen for loadInboundRequests actions
      switchMap(() => this.requestsService.getInboundRequests()), // Make an API call to fetch inbound requests
      map((response) => loadInboundRequestsSuccess({ inboundRequests: response })), // Dispatch success action with the response
      catchError(() => of(loadInboundRequestsFailure())), // Dispatch failure action if an error occurs
    );
  });

  setProductInboundRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setProductInboundRequest), // Listen for setProductInboundRequest actions
      switchMap(({ id }) => this.apiService.getProductInboundRequest(id)), // Make an HTTP request to fetch respective product data
      map((response) => setProductInboundRequestSuccess({ product: response })), // Dispatch success action with the response
      catchError(() => of(setProductInboundRequestFailure())), // Dispatch failure action if an error occurs
    );
  });
}
