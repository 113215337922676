import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, TreeNode } from 'primeng/api';
import {
  StepFootprintData,
  SupplyChainEmissions,
} from 'src/app/pages/aux-modals/add-product-modal/models/add-product-state.interfaces';

import { ParentHierarchy } from '../../models/component-details.interfaces';
import { ComponentPcfStatus } from '../../models/product-component.interfaces';
import { BREADCRUMB_CHEVRON_LENGTH } from '../constants/component.constants';

type recursiveSumChildrenParams = {
  head: TreeNode;
  aggregatedLabel: string;
  aggregateResultLabel: string;
  weighted?: boolean;
  weightLabel?: string;
  constraintFunction?: (node: TreeNode) => boolean;
};

/**
 * Displays a warning message in the supplier details page and request pcf dialog ( Products and Suppliers ) if the email domain matches the active user's.
 * @param message - The MessageService from PrimeNG for displaying messages
 * @param translate - The TranslateService from ngx-translate for translating messages
 * @param emailError - The error code related to email validation
 */

export function handleErrorForExistingDomainUser(
  message: MessageService,
  translate: TranslateService,
  emailError: number,
) {
  if (emailError === 400) {
    message.add({
      severity: 'warn',
      detail: translate.instant('Warnings.ExistingAccountDetectedMessage'),
      sticky: true,
      styleClass: 'p-toast-icon-close-width',
    });
  }
}

/**
 * This method build and returns tree structure for specified data.
 *
 * @param data T[]
 * @param parentFieldName string
 * @param currentFieldName string
 * @returns TreeNode<T>[]
 */
export function getTreeStructure<T extends { depth: number }>(
  data: T[],
  parentFieldName: string,
  currentFieldName: string,
): TreeNode<T>[] {
  const hierarchyData: TreeNode<T>[] = [];
  const filteredList: T[] = [];

  data.forEach((item) => {
    item.depth = 0;
  });

  data.forEach((item) => {
    if (item && !(item as any)[parentFieldName]) {
      hierarchyData.push({ data: item, children: [] });
    } else {
      filteredList.push(item);
    }
  });

  buildChildren(hierarchyData, filteredList, parentFieldName, currentFieldName);

  return hierarchyData;
}

function buildChildren<T extends { depth: number }>(
  hierarchyData: TreeNode<T>[],
  filteredList: T[],
  parentFieldName: string,
  currentFieldName: string,
): void {
  hierarchyData.forEach((h) => {
    const newFilteredData: T[] = [];
    h.children = [];

    filteredList.forEach((fi) => {
      let parentComponentId = (fi as any)[parentFieldName];
      if (parentComponentId?.lastIndexOf('#') != -1) {
        parentComponentId = parentComponentId?.substring(parentComponentId?.lastIndexOf('#') + 1);
      }

      if (h.data && (h.data as any)[currentFieldName] === parentComponentId) {
        fi.depth = h.data.depth + 1;
        h.children?.push({ data: fi, children: [] });
      } else {
        newFilteredData.push(fi);
      }
    });
    if (h?.children?.length) {
      buildChildren(h.children, newFilteredData, parentFieldName, currentFieldName);
    }
  });
}

/**
 * This method traverses tree and sums children values to parent.
 *
 * @param head TreeNode<T> root node of the tree
 * @param aggregatedLabel string name of the field of TreeNode that is being aggregated, e.g. 'value'
 * @param aggregateResultLabel string name of the field of TreeNode that will hold the aggregated value, e.g. 'childrenSum'
 * @param weighted boolean whether the aggregation should be weighted, e.g. if you want to multiply children values by their quantity
 * @param weightLabel string name of the field of TreeNode that holds the quantity information, e.g. 'quantity'
 * @returns TreeNode<T>[]
 */
export function recursiveSumChildren<T>({
  head,
  aggregatedLabel,
  aggregateResultLabel,
  weighted,
  weightLabel,
  constraintFunction,
}: recursiveSumChildrenParams): number {
  if (!head.children || head.children.length === 0) {
    // Leaf nodes will reflect their value to the aggregate result label, but this value will represent value per unit
    (head.data[aggregateResultLabel as keyof T] as number) = head.data[
      aggregatedLabel as keyof T
    ] as number;
    return 0;
  }

  let childrenSum = 0;

  head.children.forEach((child) => {
    if (constraintFunction?.(child)) {
      const childValue = (child.data[aggregatedLabel as keyof T] as number) ?? 0;

      childrenSum += childValue;
    }

    childrenSum += recursiveSumChildren({
      head: child,
      aggregatedLabel,
      aggregateResultLabel,
      weighted,
      weightLabel,
      constraintFunction,
    });
  });

  let quantity = 1;
  if (weighted) {
    quantity = head.data[weightLabel as keyof T] as number;
  }

  (head.data[aggregateResultLabel as keyof T] as number) = childrenSum * quantity;

  return childrenSum * quantity;
}
export function isShift() {
  const getCurrentClientName = window.location.hostname.split('.')[0];
  return getCurrentClientName === 'shift';
}

export function calculateBreadcrumbLabelsTotalWidth(
  hierarchy?: ParentHierarchy,
  componentName?: string,
  productName?: string,
  translateService?: TranslateService,
): number {
  let totalLength = 0;

  let pointer: ParentHierarchy | undefined = hierarchy;

  // Calculate total length of labels
  while (pointer) {
    totalLength += measureLabelWidth(pointer?.componentName ?? '') + BREADCRUMB_CHEVRON_LENGTH;
    pointer = pointer.child;
  }

  totalLength +=
    measureLabelWidth(translateService?.instant('Breadcrumb.Products')) + BREADCRUMB_CHEVRON_LENGTH;
  totalLength += measureLabelWidth(componentName ?? '') + BREADCRUMB_CHEVRON_LENGTH;
  totalLength += measureLabelWidth(productName ?? '');

  return totalLength;
}

function measureLabelWidth(label: string): number {
  const hiddenElement = document.createElement('span');
  hiddenElement.style.visibility = 'hidden';
  hiddenElement.style.position = 'absolute';
  hiddenElement.style.whiteSpace = 'nowrap';
  hiddenElement.innerText = label;
  document.body.appendChild(hiddenElement);
  const width = hiddenElement.offsetWidth;
  document.body.removeChild(hiddenElement);
  return width;
}

export function createSupplyChainEmissions(source: StepFootprintData): SupplyChainEmissions {
  return {
    fossilGhgEmissions: source?.supplyFossilGhgEmissions,
    // Commented out all the references to aircraftGhgEmissions in all Code and it has possibility of reintegration in the future if needed
    // aircraftGhgEmissions: source.supplyAircraftGhgEmissions,
    dLucGhgEmissions: source.supplyDlucGhgEmissions,
    landManagementGhgEmissions: source.supplyLandManagementGhgEmissions,
    otherBiogenicGhgEmissions: source.supplyOtherBiogenicGhgEmissions,
    biogenicCarbonWithdrawal: source.supplyBiogenicCarbonWithdrawal,
    fossilCarbonContent: source.supplyFossilCarbonContent,
    biogenicCarbonContent: source.supplyBiogenicCarbonContent,
    primaryDataSharePercent: source.supplyPrimaryDataShare,
  };
}

export const statusSortOrder = [
  {
    sortOrder: 0,
    status: ComponentPcfStatus.RequestNotSent,
  },
  {
    sortOrder: 1,
    status: ComponentPcfStatus.RequestSent,
  },
  {
    sortOrder: 2,
    status: ComponentPcfStatus.ResponseNoData,
  },
  {
    sortOrder: 3,
    status: ComponentPcfStatus.ResponseGateToGate,
  },
  {
    sortOrder: 4,
    status: ComponentPcfStatus.ResponseCradleToGate,
  },
];

/**
 * Checks if the reporting period in the form is valid.
 *
 * @param form - The form group containing the reporting period control.
 * @returns A boolean indicating whether the reporting period is valid.
 * i.e. the reporting period has 2 values and the start date is before the end date.
 */
export function isReportingPeriodValid<T extends { [K in keyof T]: AbstractControl<any, any> }>(
  form: FormGroup<T>,
): boolean {
  if (!form.get('reportingPeriod')?.value) return false;

  const reportingPeriod = form.get('reportingPeriod')?.value as Date[];

  return reportingPeriod.length === 2 && reportingPeriod[0] <= reportingPeriod[1];
}
