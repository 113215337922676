import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebSocketSubject, WebSocketSubjectConfig, webSocket } from 'rxjs/webSocket';

import { environment } from '../../../environments/environment';
import { Notification } from '../../shared/models/notification.interface';

@Injectable({
  providedIn: 'root',
})
export class WebscoketService {
  websocketSubject?: WebSocketSubject<Notification>;
  isWocketStarted$ = new BehaviorSubject<boolean>(false);

  constructor() {
    environment.notifications.websocketEndpoint().then((notificationsURL) => {
      this.configureWebsocket(notificationsURL);
    });
  }

  configureWebsocket(notificationsURL: string) {
    const wsConfig: WebSocketSubjectConfig<Notification> = {
      url: notificationsURL,
      deserializer: (e) => {
        try {
          return JSON.parse(e.data);
        } catch (e) {
          return { message: null };
        }
      },
    };
    this.websocketSubject = webSocket(wsConfig);
    this.isWocketStarted$.next(true);
  }
}
