<ng-container *ngIf="modalConfig$ | async as config">
  <p-dialog
    #dialog
    [draggable]="false"
    [resizable]="false"
    [breakpoints]="{ '1000px': '60vw' }"
    [contentStyle]="{ width: config?.width, height: config?.height }"
    [(visible)]="showModalContainer"
    [showHeader]="false"
    [modal]="true"
    (onHide)="onModalHide()"
  >
    <router-outlet></router-outlet>
  </p-dialog>
</ng-container>
