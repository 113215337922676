import { CarbonProductIdentifier } from './product.domain';

export enum NotificationEventType {
  RequestForPCF = 'Request For PCF',
  AnswerForPCFRequest = 'Answer for PCF Request',
  UpdatedAnswerForPCFRequest = 'Updated answer for PCF Request',
  UpdatedComponentValuesAvailable = 'Updated Component values available',
  BOMUploaded = 'BOMUploaded',
  SecondaryRequestEmailSent = 'Secondary request Email Sent',
  BomUploadError = 'Bom Upload Error',
  BomUploadErrorWithDownloadLink = '',
}

export interface Notification {
  eventType: NotificationEventType;
  fromTenantId: string;
  toTenantId: string;
  userSub: string;
  userName: string;
  tenantName: string;
  productId: string;
  productName: string;
  componentId?: string;
  componentName?: string;
  componentIdentifier?: CarbonProductIdentifier;
  message: string;
  messageId: string;
  created: string;
  expiresAt: number;
  downloadLink?: string;
}
