import { createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';

import {
  CarbonProductComponent,
  SelectedComponent,
} from '../../../shared/models/product-component.interfaces';

export interface ComponentsState {
  pcfRequestComponent: CarbonProductComponent | null;
  selectedComponentId: string | null;
  componentLoading: boolean;
  componentUpdating: boolean;
  requestQuoteComponents: SelectedComponent[] | null;
}

export const componentsAdapter: EntityAdapter<CarbonProductComponent> =
  createEntityAdapter<CarbonProductComponent>({
    sortComparer: false,
  });

export const initialState: ComponentsState = {
  pcfRequestComponent: null,
  selectedComponentId: null,
  componentLoading: false,
  componentUpdating: false,
  requestQuoteComponents: null,
};
