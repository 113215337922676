import { createAction, props } from '@ngrx/store';

import { CarbonProductOverviewGridItem } from '../../../shared/models/products-overview.interfaces';

export const loadProducts = createAction('[Products] Load Products');
export const loadProductsSuccess = createAction(
  '[Products] Load Products Success',
  props<{ products: CarbonProductOverviewGridItem[] }>(),
);
export const loadProductsFailure = createAction('[Products] Load Products Failure');

export const loadBomTemplateLink = createAction('[Products] Load BOM Template Link');

export const loadBomTemplateLinkSuccess = createAction(
  '[Products] Load BOM Template Link Success',
  props<{ url: string }>(),
);

export const loadBomTemplateLinkFailure = createAction('[Products] Load BOM Template Link Failure');

export const setSelectedProductId = createAction(
  '[Products] Set Selected Product Id',
  props<{ id: string }>(),
);

export const setProductAdding = createAction(
  '[Products] Set Product Adding',
  props<{ isAdding: boolean }>(),
);
