import { createAction, props } from '@ngrx/store';

// import { CarbonInboundRequest } from 'src/app/shared/models/inbound-request.domain';
import { CarbonProductInboundRequest } from '../../../shared/models/product-inbound-request.domain';

export const loadInboundRequests = createAction('[Product Inbound Request] Load Requests');
export const loadInboundRequestsSuccess = createAction(
  '[Product Inbound Request] Load Requests Success',
  props<{ inboundRequests: CarbonProductInboundRequest[] }>(),
);
export const loadInboundRequestsFailure = createAction(
  '[Product Inbound Request] Load Requests Failure',
);

export const setProductInboundRequest = createAction(
  '[Product Inbound Request] Set Product Inbound Request',
  props<{ id: string }>(),
);

export const setProductInboundRequestSuccess = createAction(
  '[Product Inbound Request] Set Product Inbound Request Success',
  props<{ product: CarbonProductInboundRequest }>(),
);

export const setProductInboundRequestFailure = createAction(
  '[Product Inbound Request] Set Product Inbound Request Failure',
);

export const resetProductInboundRequest = createAction(
  '[Product Inbound Request] Reset Product Inbound Request',
);
