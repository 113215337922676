export enum InboundRequestStatus {
  New = 'New', // Keep this for the future plan of showing 'new'
  Pending = 'Pending',
  NoData = 'No Data',
  ProductGateToGate = 'Product Gate-To-Gate',
  Completed = 'Completed',
  Canceled = 'Cancelled',
}

export enum OutboundRequestStatus {
  Pending = 'Pending',
  NoData = 'No Data',
  ProductGateToGate = 'Product Gate-To-Gate',
  Completed = 'Completed',
  Canceled = 'Cancelled',
}

export type StatusType = 'bom' | 'inbound' | 'outbound';
