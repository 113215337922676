import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { SUPPLY_CHAIN_TAB } from '../../../shared/utilities/constants/products.constants';
import { auxModalRefreshAfterClose } from '../../../store/event-bus/actions/aux-modal.actions';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'carbon-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContainerComponent {
  showConfirmation$: Observable<unknown>;
  readonly isApplyPadding$: BehaviorSubject<boolean>;

  constructor(
    private store: Store,
    private actions$: Actions,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private themeService: ThemeService,
  ) {
    this.showConfirmation$ = this.actions$.pipe(
      ofType(auxModalRefreshAfterClose),
      tap(
        ({
          showConfirmation,
          lastAddedProductId,
          bomImportFailed,
          failedProductName,
          bomDownloadUrl,
        }) => {
          if (showConfirmation && lastAddedProductId) {
            this.confirmationService.confirm({
              header: this.translate.instant('AddProduct.ConfirmationDialog.Title'),
              message: this.translate.instant('AddProduct.ConfirmationDialog.Message', {
                productViewLink: `/products/details/${lastAddedProductId}/${SUPPLY_CHAIN_TAB}`,
              }),
              rejectVisible: false,
              acceptVisible: false,
            });
          }

          if (showConfirmation && bomImportFailed && failedProductName && bomDownloadUrl) {
            this.confirmationService.confirm({
              header: this.translate.instant('AddProduct.BomManualUploadFailureDialog.Title'),
              message: this.translate.instant('AddProduct.BomManualUploadFailureDialog.Message', {
                productName: failedProductName,
                bomDownloadUrl: bomDownloadUrl,
              }),
              rejectVisible: false,
              acceptVisible: false,
            });
          }
        },
      ),
    );

    this.isApplyPadding$ = this.themeService.isApplyPadding$;
  }
}
