import { createAction, props } from '@ngrx/store';

import {
  CarbonProductComponent,
  SelectedComponent,
} from '../../../shared/models/product-component.interfaces';
import { RequestPcfPayload } from '../../../shared/models/request-pcf-payload.interface';

export const loadComponents = createAction(
  '[Components] Load Components',
  props<{ productId: string }>(),
);
export const loadComponentsSuccess = createAction(
  '[Components] load Components Success',
  props<{ pcfRequestComponent: CarbonProductComponent }>(),
);
export const loadComponentsFailure = createAction('[Components] Load Components Failure');
export const setSelectedComponentId = createAction(
  '[Components] Set Selected Component Id',
  props<{ id: string }>(),
);
export const updateComponentById = createAction(
  '[Components] Update Component By Id',
  props<{ payload: RequestPcfPayload }>(),
);

export const updateComponentSuccess = createAction(
  '[Components] Update Component Success',
  props<{ id: string }>(),
);

export const updateComponentFailure = createAction('[Components] Update Component Failure');

export const setRequestQuoteComponents = createAction(
  '[Components] Set Request Quote Components Ids',
  props<{ components: SelectedComponent[] }>(),
);
export const requestQuotesByCompIds = createAction(
  '[Components] Request Quotes By Components Ids',
  props<{ productId: string; componentIds: string[] }>(),
);

export const requestQuotesSuccess = createAction('[Components] Request Quote Components Success');

export const requestQuotesFailure = createAction('[Components] Request Quote Components Failure');
