<div class="carbon-modal h-full flex flex-column" [ngClass]="modalClasses">
  <div class="carbon-modal-header flex justify-content-between align-items-center" *ngIf="showHeader">
    <ng-content select="[header]"></ng-content>
    <i *ngIf="showCloseBtn" class="cb cb-icon-close cb-interface cursor-pointer" (click)="onModalClose($event)"></i>
  </div>

  <div class="carbon-modal-content flex flex-column overflow-y-auto flex-1">
    <ng-content></ng-content>
  </div>

  <div class="carbon-modal-footer flex justify-content-end gap-2" *ngIf="showFooter">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
