import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'carbon-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input()
  className = '';

  @Input()
  width = '100px';

  @Input()
  position: 'start' | 'center' | 'end' | 'baseline' = 'center';
}
