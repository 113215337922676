import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { ApiToStoreCallsService } from '../../../services';
import {
  loadBomTemplateLink,
  loadBomTemplateLinkFailure,
  loadBomTemplateLinkSuccess,
  loadProducts,
  loadProductsFailure,
  loadProductsSuccess,
} from '../actions/products.actions';

@Injectable()
export class ProductsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private apiService: ApiToStoreCallsService,
  ) {}

  loadProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadProducts),
      switchMap(() => this.apiService.getProducts()),
      map((response) => loadProductsSuccess({ products: response })),
      catchError(() => of(loadProductsFailure())),
    );
  });

  loadBomTemplateLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadBomTemplateLink),
      switchMap(() => this.apiService.getLink('bom')),
      map(loadBomTemplateLinkSuccess),
      catchError(() => of(loadBomTemplateLinkFailure())),
    );
  });
}
