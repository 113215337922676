import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ImageWithLoadingModule } from '../image-with-loading/image-with-loading.module';
import { EmptyScreenComponent } from './empty-screen.component';

@NgModule({
  declarations: [EmptyScreenComponent],
  imports: [CommonModule, TranslateModule, ImageWithLoadingModule],
  exports: [EmptyScreenComponent],
})
export class EmptyScreenModule {}
