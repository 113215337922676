import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const BOM_LOCAL_STORAGE_KEY = 'bomUpload';

@Injectable({
  providedIn: 'root',
})
export class BomUploadStorageService {
  bomUploadProducts$ = new BehaviorSubject<string[]>(this.getExistingProductIds());

  setBomProductToStorage(productId: string) {
    const existingIds: string[] = this.getExistingProductIds();
    existingIds.push(productId);
    localStorage.setItem(BOM_LOCAL_STORAGE_KEY, JSON.stringify(existingIds));
    this.bomUploadProducts$.next(existingIds);
  }

  removeProductFromStorage(productId: string) {
    const existingIds: string[] = this.getExistingProductIds();
    if (existingIds.includes(productId)) {
      const newProductIds = existingIds.filter((prodId: string) => prodId !== productId);
      localStorage.setItem(BOM_LOCAL_STORAGE_KEY, JSON.stringify(newProductIds));
      this.bomUploadProducts$.next(newProductIds);
    }
  }

  getExistingProductIds(): string[] {
    return JSON.parse(localStorage.getItem(BOM_LOCAL_STORAGE_KEY) ?? '[]');
  }
}
