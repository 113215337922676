import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'carbon-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpIconComponent {
  @Input()
  helpTextKey = '';

  @Input()
  tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

  @Input()
  purpose: 'error' | 'warning' | 'grey' | 'interface' = 'interface';

  @Input()
  enableHover = false;

  isHovering = false;
}
