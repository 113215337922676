import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'carbon-empty-screen',
  templateUrl: './empty-screen.component.html',
  styleUrls: ['./empty-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyScreenComponent {
  @Input()
  imageSrc = 'assets/images/no-image.png';

  @Input()
  heading = '';

  @Input()
  description = '';
}
