import { createReducer, on } from '@ngrx/store';

import {
  loadSuppliers,
  loadSuppliersFailure,
  loadSuppliersSuccess,
} from './actions/suppliers.actions';
import { SuppliersState, initialState, suppliersEntityAdapter } from './models/suppliers.state';

export const suppliersReducer = createReducer(
  initialState,

  on(
    loadSuppliers,
    (state): SuppliersState => ({
      ...state,
      suppliersLoading: true,
    }),
  ),

  on(
    loadSuppliersSuccess,
    (state, { suppliers }): SuppliersState => ({
      ...state,
      suppliers: suppliersEntityAdapter.setAll(suppliers, state.suppliers),
      suppliersLoading: false,
    }),
  ),

  on(
    loadSuppliersFailure,
    (state): SuppliersState => ({
      ...state,
      suppliersLoading: false,
    }),
  ),
);
