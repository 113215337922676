import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { CarbonSuppliersOverviewGridItem } from '../../../shared/models/suppliers/suppliers-overview.interfaces';

export interface SuppliersState {
  suppliers: EntityState<CarbonSuppliersOverviewGridItem>;
  suppliersLoading: boolean;
}

export const suppliersEntityAdapter = createEntityAdapter<CarbonSuppliersOverviewGridItem>();

export const initialState: SuppliersState = {
  suppliers: suppliersEntityAdapter.getInitialState(),
  suppliersLoading: false,
};
