import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { fromEvent, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { Notification, NotificationEventType } from 'src/app/shared/models/notification.interface';

import { ApiToStoreCallsService } from '../../../services';
import { BomUploadStorageService } from '../../../services/bom-upload-storage.service';
import { RequestQuoteStorageService } from '../../../services/request-quote-storage.service';
import { PrimeNgSwitcherEvent } from '../../../shared/models/prime-ng-events.interfaces';
import { ThemeMode } from '../../../shared/models/theme-mode.enum';
import { untilDestroy } from '../../../shared/utilities';
import { auxModalRefreshAfterClose } from '../../../store/event-bus/actions/aux-modal.actions';
import { preferredThemeChange } from '../../../store/user-preferences/actions/user-preferences.actions';
import { UserProfileState } from '../../../store/user-profile/models/user-profile.state';
import { selectUserProfileState } from '../../../store/user-profile/selectors/user-profile.selectors';
import { AuthCognitoService } from '../../services/auth-cognito.service';
import { ThemeService } from '../../services/theme.service';
import { WebscoketService } from '../../services/websocket.service';

@Component({
  selector: 'carbon-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent extends untilDestroy(class {}) implements OnInit {
  showMobileMenu = false;
  helpPanelActive = false;
  userPanelActive = false;
  notificationPanelActive = false;
  topBarItemClick = false;
  profile: UserProfileState;
  newNotification = false;
  NotificationEventType = NotificationEventType;

  constructor(
    private readonly themeService: ThemeService,
    private authService: AuthCognitoService,
    private router: Router,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private apiService: ApiToStoreCallsService,
    private webscoketService: WebscoketService,
    private translate: TranslateService,
    private requestQuoteStorageService: RequestQuoteStorageService,
    private bomUploadStorageService: BomUploadStorageService,
  ) {
    super();
    fromEvent(document.body, 'click')
      .pipe(takeUntil(this.destroy$$))
      .subscribe(() => {
        if (!this.topBarItemClick) {
          this.helpPanelActive = false;
          this.notificationPanelActive = false;
          this.userPanelActive = false;
        }

        this.topBarItemClick = false;
        /* TODO Refactor this to use observables instead */
        this.cdr.detectChanges();
      });

    this.profile = {} as UserProfileState;
    this.store.select(selectUserProfileState).subscribe((profile) => {
      this.profile = profile;
    });
  }

  handleMessageReceived(message: Notification | null) {
    if (message && message.eventType) {
      this.messageService.clear();
      if (message.eventType === NotificationEventType.SecondaryRequestEmailSent) {
        this.messageService.add({
          severity: 'success',
          life: 3000,
          detail: this.translate.instant('RequestQuote.RequestQuoteSuccessMessage'),
        });
        // Remove the requested product and component ids from local storage
        if (message.componentId) {
          this.requestQuoteStorageService.removeProductComponents(
            message.productId,
            message.componentId,
          );
        }
      } else if (message.eventType === NotificationEventType.BomUploadError) {
        this.bomUploadStorageService.removeProductFromStorage(message.productId);
        if (message.downloadLink) {
          //if bom upload fails

          this.store.dispatch(
            auxModalRefreshAfterClose({
              showConfirmation: true,
              bomImportFailed: true,
              failedProductName: message.productName,
              bomDownloadUrl: message.downloadLink,
            }),
          );
        } else {
          //success
          this.store.dispatch(auxModalRefreshAfterClose({ showConfirmation: false }));
          this.messageService.add({
            key: 'c',
            severity: 'success',
            life: 3000,
            data: message,
          });
        }
      } else {
        this.messageService.add({
          key: 'c',
          severity: 'info',
          life: 3000,
          data: message,
        });
      }
      this.newNotification = true;
      this.cdr.detectChanges();
    }
  }

  handleConnectionError(error: unknown) {
    console.error(`WebSocketSubject Error @ [${new Date().toISOString()}]:`, error);
  }

  handleCompletion() {
    //console.log(`WebSocketSubject Completed @ [${new Date().toISOString()}]`);
  }
  onReject() {
    this.messageService.clear('c');
  }
  ngOnInit(): void {
    this.webscoketService.isWocketStarted$
      .pipe(
        takeUntil(this.destroy$$),
        switchMap((isStarted) => {
          if (isStarted && this.webscoketService.websocketSubject) {
            return this.webscoketService.websocketSubject;
          }
          return of(null);
        }),
      )
      .subscribe({
        next: (message) => this.handleMessageReceived(message),
        error: (error) => this.handleConnectionError(error),
        complete: () => this.handleCompletion(),
      });

    this.apiService
      .getNotifications()
      .pipe(takeUntil(this.destroy$$))
      .subscribe((notifications) => {
        if (notifications.length > 0) {
          this.newNotification = true;
          this.cdr.detectChanges();
        }
      });
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  onThemeChange(event: PrimeNgSwitcherEvent): void {
    this.themeService.setTheme(event.checked);
    this.store.dispatch(
      preferredThemeChange({
        theme: event.checked ? ThemeMode.Dark : ThemeMode.Light,
      }),
    );
  }

  onSignOutClick(): void {
    this.webscoketService.websocketSubject?.unsubscribe();
    this.authService
      .signOut()
      .pipe(
        tap(() => localStorage.clear()),
        take(1),
      )
      .subscribe(() => {
        // do a page refresh just to make sure aws objects were cleared after logout
        this.router.navigateByUrl('login').then(() => document.location.reload());
      });
  }

  toggleMenu(menu: string): void {
    this.topBarItemClick = true;
    this.helpPanelActive = false;

    switch (menu) {
      case 'account':
        this.userPanelActive = !this.userPanelActive;
        this.notificationPanelActive = false;
        break;
      case 'notifications':
        this.notificationPanelActive = !this.notificationPanelActive;
        this.newNotification = false;
        this.userPanelActive = false;
        break;
      case 'help':
        this.helpPanelActive = !this.helpPanelActive;
        break;
      default:
        break;
    }
  }
}
