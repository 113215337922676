import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { API } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { Observable, catchError, from, map, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AmplifyApiConfig } from '../../shared/models/amplify-api-config.interface';
import { CarbonResponse } from '../../shared/models/api.interfaces';

/** We are using amplify instead HttpClient to keep things consistent
 * Amplify provides all necessary API for working with AWS infrastructure
 * in a designed way
 * We are using wrappers for API promises to make them work in observable-like manner */
@Injectable({
  providedIn: 'root',
})
export class AmplifyApiService {
  constructor(
    private readonly router: Router,
    private readonly messageService: MessageService,
    private translate: TranslateService,
  ) {}

  private handleError(error: any) {
    if (!environment.production) console.error(error);

    if (error.response) {
      let errorMessage = '';
      switch (error.response.status) {
        case 400:
          errorMessage = this.translate.instant('Errors.HttpErrors.400');
          break;
        case 401:
          this.router.navigateByUrl('/login');
          break;
        case 403:
          errorMessage = this.translate.instant('Errors.HttpErrors.403');
          break;
        case 404:
          this.router.navigateByUrl('/not-found');
          break;
        case 500:
          errorMessage = this.translate.instant('Errors.HttpErrors.500');
          break;
        default:
          errorMessage = this.translate.instant('Errors.HttpErrors.Default');
          break;
      }
      this.messageService.add({
        severity: 'error',
        life: 3000,
        detail: this.translate.instant('Errors.HttpErrors.Error', {
          code: error.response.status,
          message: errorMessage,
        }),
      });
    }
    return throwError(() => error);
  }

  get<T>(config: AmplifyApiConfig, locallyHandleErrors?: boolean): Observable<T> {
    const { apiName, path, init } = config;
    const params = init ?? {};
    return from(API.get(apiName, path, params)).pipe(
      map((response: CarbonResponse<T>) => response.body.data),
      catchError((e) => (!locallyHandleErrors ? this.handleError(e) : throwError(() => e))),
    );
  }

  post<T, R>(config: AmplifyApiConfig, body: T, locallyHandleErrors?: boolean): Observable<R> {
    const { apiName, path } = config;
    const params = body ? { body } : {};
    return from(API.post(apiName, path, params)).pipe(
      map((response: CarbonResponse<R>) => response.body.data),
      catchError((e) => (!locallyHandleErrors ? this.handleError(e) : throwError(() => e))),
    );
  }

  patch<T, R>(config: AmplifyApiConfig, body: T, locallyHandleErrors?: boolean): Observable<R> {
    const { apiName, path } = config;
    const params = body ? { body } : {};
    return from(API.patch(apiName, path, params)).pipe(
      map((response: CarbonResponse<R>) => response.body.data),
      catchError((e) => (!locallyHandleErrors ? this.handleError(e) : throwError(() => e))),
    );
  }

  delete<T>(config: AmplifyApiConfig, locallyHandleErrors?: boolean): Observable<T> {
    const { apiName, path, init } = config;
    const params = init ?? {};
    return from(API.del(apiName, path, params)).pipe(
      map((response: CarbonResponse<T>) => response.body.data),
      catchError((e) => (!locallyHandleErrors ? this.handleError(e) : throwError(() => e))),
    );
  }
}
