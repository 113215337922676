import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const THEME_PREFIX = 'carbon-theme';
enum ThemeBundle {
  Light = 'prime-ng-carbon-light.css',
  Dark = 'prime-ng-carbon-dark.css',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  readonly isApplyPadding$ = new BehaviorSubject<boolean>(true);

  setTheme(isDark: boolean): void {
    const theme = isDark ? ThemeBundle.Dark : ThemeBundle.Light;
    getThemeElement()?.setAttribute('href', theme);
  }
}

function getThemeElement() {
  return getLinkElement() || createLinkElement();
}

function getLinkElement(): HTMLLinkElement | null {
  return document.head.querySelector(`link[rel="stylesheet"].${THEME_PREFIX}`) as HTMLLinkElement;
}

function createLinkElement(): HTMLLinkElement {
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.setAttribute('type', 'text/css');
  linkEl.classList.add(THEME_PREFIX);
  document.head.appendChild(linkEl);
  return linkEl;
}
