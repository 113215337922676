import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Dialog } from 'primeng/dialog';
import { Observable } from 'rxjs';

import {
  auxModalClickCancelOrClose,
  auxModalRefreshAfterClose,
  resetModalHeightWidth,
} from '../../../store/event-bus/actions/aux-modal.actions';
import { AuxModalState } from '../../../store/event-bus/models/aux-modal.state';
import { selectAuxModalConfig } from '../../../store/event-bus/selectors/aux-modal.selectors';
import { resetProductInboundRequest } from '../../../store/product-inbound-request/actions/product-inbound-request.actions';

@Component({
  selector: 'carbon-aux-modal-view-container',
  templateUrl: './aux-modal-view.component.html',
  styleUrls: ['./aux-modal-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuxModalViewComponent implements OnInit {
  @ViewChild('dialog')
  dialog!: Dialog;

  showModalContainer = false;
  modalConfig$: Observable<AuxModalState>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actions: Actions,
    private store: Store,
  ) {
    this.modalConfig$ = this.store.select(selectAuxModalConfig);
  }

  ngOnInit(): void {
    // we need to show the modal immediately because we use auxiliary routes
    this.showModalContainer = true;

    this.actions
      .pipe(ofType(auxModalRefreshAfterClose, auxModalClickCancelOrClose))
      .subscribe(() => {
        this.store.dispatch(resetModalHeightWidth());
        this.dialog.close(new Event('close'));
        this.store.dispatch(resetProductInboundRequest());
      });
  }

  onModalHide() {
    // remove aux route from URL when close the container
    const urlWithoutAuxiliaryRoute = this.router
      .createUrlTree(['.'], { relativeTo: this.route })
      .root.children[PRIMARY_OUTLET].toString();

    this.router.navigateByUrl(urlWithoutAuxiliaryRoute, { replaceUrl: true });
  }
}
