import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { MultiLevelTreeNode } from '../../models/multi-level-tree-node.interface';

/**
 * @description A custom CarbonBlock Tree Dropdown that loads Array entities list depends on position of Parent or Child
 * Default it shows all Items at 0th level of Array
 * On Selection of nth Item, this tree dropdown will load all children of this nth item by replacing existing list with a Back icon on the top to go back to Parent
 * The dropdown also has a Search functionality to search all items in hierarchy of Parent child array
 */
@Component({
  selector: 'carbon-tree-dropdown',
  templateUrl: './carbon-tree-dropdown.html',
  styleUrls: ['./carbon-tree-dropdown.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarbonTreeDropdownComponent implements OnInit, OnChanges {
  @Input() isTooltip = false;
  @Input() selectedItemLabel?: string;
  @Input() isBelowTextLabel = true;
  @Input() items: MultiLevelTreeNode[] = [];
  @Input() isLoading = false;
  @Input() placeholder?: string;
  @Input() placeholderOnFocus?: string;

  @Output() itemSelectionEvent = new EventEmitter<object | null>();

  treeDropdownItem: MultiLevelTreeNode[] = [];
  customVisible = false;
  treeDropdownValue?: string;
  searchText = '';
  flattenedLabels: MultiLevelTreeNode[] = [];
  filteredLabels: MultiLevelTreeNode[] = [];
  flattenedItems: MultiLevelTreeNode[] = [];
  currentSelectedItem?: MultiLevelTreeNode;

  /**
   * @description When we click outside dropdown, it should close it
   */
  @HostListener('document:click', ['$event'])
  onDocumentClick() {
    this.customVisible = false;
  }

  ngOnInit(): void {
    this.treeDropdownItem = this.items;
    this.flattenArray(this.treeDropdownItem);
    if (this.selectedItemLabel) {
      this.treeDropdownValue = this.selectedItemLabel;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.treeDropdownValue = changes['selectedItemLabel']?.currentValue ?? this.selectedItemLabel;
    if (changes['items']?.currentValue) {
      this.treeDropdownItem = changes['items']?.currentValue;
    }
    this.flattenArray(this.items);
  }

  /**
   * @description It flattens the multi level object into a flatten array
   */
  private flattenArray(data: MultiLevelTreeNode[]): void {
    for (const item of data) {
      this.flattenedItems.push(item);

      if (item.children) {
        this.flattenArray(item.children);
      }
    }
  }

  /**
   * This method makes sure to return search matched results based on Flatten array created
   */
  handleSearchByLabel(searchLabelText?: string): void {
    if (searchLabelText && searchLabelText !== null && searchLabelText !== '') {
      this.searchText = searchLabelText;
      const lowerCasedText = searchLabelText.toLowerCase();

      const getFilteredItems = this.flattenedItems
        .filter(
          (item) => item?.selectable !== false && item.label.toLowerCase().includes(lowerCasedText),
        )
        .reduce((acc: { [label: string]: MultiLevelTreeNode }, item: MultiLevelTreeNode) => {
          acc[item.label] = item;
          return acc;
        }, {});

      this.filteredLabels = Object.values(getFilteredItems);
    } else {
      this.treeDropdownItem = this.items;
      this.searchText = '';
      this.currentSelectedItem = undefined;
    }
  }

  /**
   * @description We will for sure need to remove any such code that processes existing data to get Parent child relationship
   * This method will make sure that items has correct parent based on the Id
   */
  setParentItem(item?: MultiLevelTreeNode) {
    if (item !== undefined) {
      if (item?.data?.length === 1) {
        this.currentSelectedItem = undefined;
        this.treeDropdownItem = this.items;
      } else {
        this.currentSelectedItem = this.flattenedItems.find(
          (flattenedItem) => flattenedItem.data === item?.parentId,
        );
        this.treeDropdownItem = this.currentSelectedItem?.children ?? [];
      }
    } else {
      this.treeDropdownItem = this.items;
    }
  }

  /**
   * @description Method to be called when we select an item in custom dropdown
   * It will load children if item has children else item will be selected and emitted to parent
   */
  setItem(item: MultiLevelTreeNode) {
    this.scrollToElement();
    if (item?.selectable === false) {
      this.currentSelectedItem = item;
      this.treeDropdownItem = item.children ?? [];
    } else {
      this.treeDropdownValue = item.label;
      this.customVisible = false;
      this.itemSelectionEvent.emit(item);
    }
  }

  /**
   * @description Selection to be made on search results of custom dropdown
   */
  handleSelectOptionOnSearch(currentLabel: MultiLevelTreeNode) {
    this.treeDropdownValue = currentLabel.label;
    this.customVisible = false;
    this.searchText = '';
    this.itemSelectionEvent.emit(currentLabel);
  }

  onInputFocus() {
    this.customVisible = true;
  }

  clearSearchInput() {
    this.treeDropdownValue = '';
    this.searchText = '';
    this.currentSelectedItem = undefined;
    this.treeDropdownItem = this.items;
    this.itemSelectionEvent.emit(null);
  }

  private scrollToElement(): void {
    const element = document.querySelector('.custom-template');
    if (element) {
      element.scrollTop = 0;
    }
  }
}
