<span
  class="cursor-pointer"
  [pTooltip]="helpTextKey | translate"
  [tooltipPosition]="tooltipPosition"
  (mouseenter)="isHovering = true"
  (mouseleave)="isHovering = false"
>
  <i
    class="cb cb-icon-warning-small"
    [ngClass]="enableHover ? (isHovering ? 'cb-warning' : 'cb-' + purpose) : 'cb-' + purpose"
  ></i>
</span>
