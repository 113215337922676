<div class="relative p-fluid mt-1">
  <div (click)="$event.stopPropagation()">
    <!-- Input field -->
    <span class="p-input-icon-left p-float-label">
      <i class="pi pi-search"></i>
      <ng-container *ngIf="isLoading; else chevronIon">
        <div class="custom-template-actionElement mt-2 absolute">
          <carbon-loader width="20px"></carbon-loader>
        </div>
      </ng-container>
      <ng-template #chevronIon>
        <i class="pi pi-chevron-down cursor-pointer custom-template-actionElement" (click)="onInputFocus()"></i>
      </ng-template>
      <div class="p-input-icon-right">
        <i
          *ngIf="isTooltip"
          class="cb cb-icon-help-circle cursor-pointer mt-2"
          [pTooltip]="'AddProduct.Info.TreeDropdownTooltipForDropdown' | translate"
          [hideDelay]="1500"
        ></i>
      </div>

      <input
        type="text"
        pInputText
        [(ngModel)]="treeDropdownValue"
        (focus)="onInputFocus()"
        (input)="handleSearchByLabel(treeDropdownValue)"
        [disabled]="isLoading"
        [value]="treeDropdownValue"
        class="tree-dropdown-input text-overflow-ellipsis"
        [ngClass]="{ 'tree-dropdown-input--placeholder': true}"
        [placeholder]="customVisible ? placeholderOnFocus : placeholder"
      />

      <!-- Clear Icon -->
      <span *ngIf="treeDropdownValue" class="p-input-icon-right">
        <i
          class="pi pi-times cursor-pointer top-50 border-circle p-1 text-xs text-white mr-7"
          (click)="clearSearchInput()"
        ></i>
      </span>
    </span>

    <!-- Dropdown content -->
    <div class="custom-template absolute bg-white h-20rem overflow-y-auto shadow-3" *ngIf="customVisible">
      <!-- Header -->
      <div
        class="flex align-items-center px-2 py-3 pt-3"
        [ngClass]="currentSelectedItem ? 'header-content-wrapper cursor-pointer' :''"
        (click)="setParentItem(currentSelectedItem)"
      >
        <i
          *ngIf="!searchText && currentSelectedItem"
          class="pi pi-arrow-left p-2 border-circle mr-2 text-sm font-bold"
        ></i>
        <span class="font-bold pl-2"
          >{{ searchText ? ('AddProduct.Info.SearchResult' | translate) : (currentSelectedItem ?
          currentSelectedItem.label : ('AddProduct.Info.AllCategories' | translate)) }}</span
        >
      </div>

      <!-- Custom Template for search-->
      <div *ngIf="searchText; else labelDataBlock">
        <ul class="p-0 m-0">
          <li
            class="flex justify-content-between white-space-normal cursor-pointer m-0 pl-3 py-3 custom-template-list-item"
            *ngIf="filteredLabels.length === 0; else labelsTemplate"
          >
            {{'AddProduct.Info.NoResultsFound' | translate}}
          </li>
          <ng-template #labelsTemplate>
            <li
              *ngFor="let item of filteredLabels"
              class="flex justify-content-between white-space-normal cursor-pointer custom-template-list-item m-0 pl-3 py-3"
              (click)="handleSelectOptionOnSearch(item)"
            >
              <div [innerHTML]="item.label | highlightText : searchText"></div>
            </li>
          </ng-template>
        </ul>
      </div>

      <!-- Custom Template for items-->
      <ng-template #labelDataBlock>
        <ul class="p-0 mt-0">
          <li
            *ngFor="let item of treeDropdownItem"
            class="flex justify-content-between white-space-normal cursor-pointer m-0 pl-3 py-3 custom-template-list-item"
            (click)="setItem(item)"
          >
            {{ item.label }} {{ item?.selectable === false ? '('+item.data+')' : '' }}
            <i class="pi text-xl pi-angle-right mr-2" *ngIf="item?.selectable === false"></i>
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</div>
