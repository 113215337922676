import { ThemeMode } from '../../../shared/models/theme-mode.enum';

export interface UserPreferencesState {
  preferredTheme: ThemeMode;
  preferredLanguage: string;
}

export const initialState: UserPreferencesState = {
  preferredTheme: ThemeMode.Light,
  preferredLanguage: '',
};
