/**
 * This code was got from PrimNG table component to create a fallback for default single sort when we don't need
 * a custom logic, will use this until they provide an ability to call this method directly from table component
 *
 * Types for helpers resolveFieldData and isFunction are provided by PrimNG
 */

/* eslint-disable */
export interface PrimeDataItem {
  [key: string]: string | number | boolean;
}
export function primeNgDefaultSingleSort(
  dataArray: Array<PrimeDataItem>,
  field: string,
  order: number,
) {
  dataArray.sort((data1, data2) => {
    const value1 = resolveFieldData(data1, field);
    const value2 = resolveFieldData(data2, field);
    let result;

    if (value1 === null && value2 !== null) result = -1;
    else if (value1 !== null && value2 === null) result = 1;
    else if (value1 === null && value2 === null) result = 0;
    else if (typeof value1 === 'string' && typeof value2 === 'string')
      result = value1.localeCompare(value2);
    else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

    return order * result;
  });
}

export function resolveFieldData(data: any, field: any): any {
  if (data && field) {
    if (isFunction(field)) {
      return field(data);
    } else if (field.indexOf('.') == -1) {
      return data[field];
    } else {
      const fields: string[] = field.split('.');
      let value = data;
      for (let i = 0, len = fields.length; i < len; ++i) {
        if (value == null) {
          return null;
        }
        value = value[fields[i]];
      }
      return value;
    }
  } else {
    return null;
  }
}

function isFunction(obj: any) {
  return !!(obj && obj.constructor && obj.call && obj.apply);
}
