import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageModule } from 'primeng/image';
import { SkeletonModule } from 'primeng/skeleton';

import { ImageWithLoadingComponent } from './image-with-loading.component';

@NgModule({
  declarations: [ImageWithLoadingComponent],
  imports: [CommonModule, ImageModule, SkeletonModule],
  exports: [ImageWithLoadingComponent],
})
export class ImageWithLoadingModule {}
