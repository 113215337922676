import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuillModule } from 'ngx-quill';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ModalViewButtonConfig, ModalViewConfig } from './modules/modal-view-container/models';
import { MODAL_VIEW_CONFIG } from './modules/modal-view-container/tokens/modal.tokens';
import { LoginModule } from './pages/login/login.module';
import { MainModule } from './pages/main/main.module';
import { SignUpModule } from './pages/sign-up/sign-up.module';
import { SharedModule } from './shared/shared.module';
import { metaReducers, reducers } from './store';
import { ComponentsEffects } from './store/components/effects/components.effects';
import { ProductInboundEffects } from './store/product-inbound-request/effects/product-inbound-request.effects';
import { ProductsEffects } from './store/products/effects/products.effects';
import { CarbonRouterStateSerializer } from './store/router/router.reducer';
import { SharePcfEffects } from './store/share-pcf/effects/share-pcf.effects';
import { SuppliersEffects } from './store/suppliers/effects/suppliers.effects';

function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

const defaultButtonConfig: ModalViewButtonConfig = {
  label: '',
  show: false,
};
const initialModalConfig: ModalViewConfig = {
  header: '',
  cancelButton: defaultButtonConfig,
  backButton: defaultButtonConfig,
  nextButton: defaultButtonConfig,
  saveShareButton: defaultButtonConfig,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    SignUpModule,
    // Eager loaded modules
    LoginModule, // Eager loaded since we may need to go here right away
    MainModule,
    // CarbonBlock Modules
    CoreModule, // Singleton objects (services, components that are loaded only once, etc.)
    SharedModule, // Shared (multi-instance) objects
    // Angular Modules
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // Other Modules
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CarbonRouterStateSerializer,
    }),
    EffectsModule.forRoot([
      ProductsEffects,
      ComponentsEffects,
      ProductInboundEffects,
      SharePcfEffects,
      SuppliersEffects,
    ]),
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: MODAL_VIEW_CONFIG,
      useValue: new BehaviorSubject<ModalViewConfig>(initialModalConfig),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
