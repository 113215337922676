export enum PcfReadiness {
  CradleToGateAggregated = '1a',
  CradleToGateDisaggregated = '1b',
  GateToGate = '2',
  CompanyLevelOnly = '3',
  NoData = '4',
}

export enum IncomingProductFootprintType {
  SingleValue = 'singleValue',
  OwnAndSupplyChain = 'ownAndSupplyChain',
}
