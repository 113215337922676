import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';

import { LoadingContainerModule } from '../../modules/loading-container/loading-container.module';
import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { LoginRoutingModule } from './login-routing.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    LoginRoutingModule,
    SharedModule,
    InputTextModule,
    LoadingContainerModule,
    TranslateModule.forChild(),
    MessageModule,
  ],
})
export class LoginModule {}
