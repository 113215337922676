<div class="h-full flex flex-column justify-content-center align-items-center">
  <carbon-image-with-loading
    [imageSrc]="imageSrc"
    [alt]="heading"
    width="17.225rem"
    height="17.225rem"
  ></carbon-image-with-loading>
  <h5>{{ heading }}</h5>
  <p class="text-center info-text" *ngIf="description !== ''">
    {{ description }}
  </p>
  <ng-content></ng-content>
</div>
