import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

import { HelpIconComponent } from './help-icon.component';

@NgModule({
  declarations: [HelpIconComponent],
  imports: [CommonModule, TooltipModule, TranslateModule],
  exports: [HelpIconComponent],
})
export class HelpIconModule {}
