import {
  ComponentRef,
  Directive,
  ElementRef,
  Host,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY,
  NEVER,
  Observable,
  distinctUntilChanged,
  fromEvent,
  mapTo,
  merge,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';

import { ControlErrorsComponent } from '../../components/control-errors/control-errors.component';
import { untilDestroy } from '../../utilities';
import { ControlErrorsContainerDirective } from './control-errors-container.directive';
import { ERRORS_CONFIG, ErrorConfig } from './control-errors.token';
import { FormSubmitDirective } from './form-submit.directive';

@Directive({
  selector: '[carbonControlErrors]',
})
export class ControlErrorsDirective extends untilDestroy(class {}) implements OnInit {
  @Input()
  customErrors: ErrorConfig = {};

  ref!: ComponentRef<ControlErrorsComponent> | null;
  container: ViewContainerRef;
  submit$: Observable<unknown>;
  reset$: Observable<Event>;
  onBlur$: Observable<Event> = EMPTY;

  get control() {
    return this.controlDir.control;
  }

  constructor(
    private translate: TranslateService,
    private controlDir: NgControl,
    private vcr: ViewContainerRef,
    private host: ElementRef,
    @Optional() controlErrorsContainer: ControlErrorsContainerDirective,
    @Optional() @Host() private formSubmit: FormSubmitDirective,
    @Inject(ERRORS_CONFIG) private errors: ErrorConfig,
  ) {
    super();
    this.container = controlErrorsContainer ? controlErrorsContainer.vcr : this.vcr;
    this.submit$ = this.formSubmit ? this.formSubmit.submit$ : EMPTY;
    this.reset$ = this.formSubmit ? this.formSubmit.reset$ : EMPTY;
  }

  ngOnInit() {
    if (this.control) {
      const statusChanges$ = this.control.statusChanges.pipe(distinctUntilChanged());
      const valueChanges$ = this.control.valueChanges;
      const controlChanges$ = merge(statusChanges$, valueChanges$);
      if (this.isInput()) {
        const blur$ = fromEvent(this.host.nativeElement, 'focusout');
        this.onBlur$ = blur$.pipe(switchMap(() => valueChanges$.pipe(startWith(true))));
      }

      const submit$ = merge(this.submit$.pipe(mapTo(true)), this.reset$.pipe(mapTo(false)));

      const changesOnSubmit$ = submit$.pipe(
        switchMap((submit) => (submit ? controlChanges$.pipe(startWith(true)) : NEVER)),
      );

      this.reset$.pipe(takeUntil(this.destroy$$)).subscribe(() => this.clearRefs());

      /* TODO: make inputs to have a config how to show errors: onBlur, OnChanges, onSubmit */
      merge(changesOnSubmit$, this.onBlur$)
        .pipe(takeUntil(this.destroy$$))
        .subscribe((value) => {
          let touched = false;
          if (typeof value === 'boolean' && value) {
            touched = value;
          }

          const controlErrors = this.control && this.control.errors;
          if (controlErrors && (this.formSubmit.isSubmitted() || touched)) {
            const firstErrorKey = Object.keys(controlErrors)[0];
            const errorFunctionFromConfig =
              this.customErrors[firstErrorKey] || this.errors[firstErrorKey];
            const errorText = this.translate.instant(
              errorFunctionFromConfig(controlErrors[firstErrorKey]),
            );
            this.setError(errorText);
          } else if (this.ref) {
            this.setError(null);
          }
        });
    }
  }

  private isInput() {
    return (
      this.host.nativeElement.tagName === 'INPUT' ||
      this.host.nativeElement.tagName === 'SELECT' ||
      this.host.nativeElement.tagName === 'TEXTAREA' ||
      this.host.nativeElement.tagName === 'P-CALENDAR' ||
      this.host.nativeElement.tagName === 'P-DROPDOWN'
    );
  }

  private setError(text: string | null) {
    if (!this.ref) {
      this.ref = this.container.createComponent(ControlErrorsComponent);
    }

    this.ref.instance.text = text;
  }

  private clearRefs(): void {
    if (this.ref) {
      this.ref.destroy();
    }
    this.ref = null;
  }
}
