import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { ModalViewContainerModule } from '../modules/modal-view-container/modal-view-container.module';
import { LoaderModule } from '../shared/components/loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { AboutComponent } from './aux-modals/about/about.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './components/footer/footer.component';
import { HelpMenuComponent } from './components/top-bar/components/help-menu/help-menu.component';
import { NotificationsMessageComponent } from './components/top-bar/components/notification-message/notification-message.component';
import { NotificationsMenuComponent } from './components/top-bar/components/notifications-menu/notifications-menu.component';
import { UserMenuComponent } from './components/top-bar/components/user-menu/user-menu.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { AuxModalViewComponent } from './containers/aux-modal-view-container/aux-modal-view.component';
import { MainContainerComponent } from './containers/main-container/main-container.component';
import { ModuleLoadedOnceGuard } from './module-loaded-once.guard';

@NgModule({
  declarations: [
    MainContainerComponent,
    AuxModalViewComponent,
    TopBarComponent,
    FooterComponent,
    BreadcrumbsComponent,
    HelpMenuComponent,
    NotificationsMenuComponent,
    AboutComponent,
    UserMenuComponent,
    NotificationsMessageComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    RippleModule,
    InputSwitchModule,
    TooltipModule,
    ButtonModule,
    SplitButtonModule,
    ScrollPanelModule,
    TranslateModule,
    BreadcrumbModule,
    FormsModule,
    DialogModule,
    ToastModule,
    ConfirmDialogModule,
    ModalViewContainerModule,
    AvatarModule,
    DividerModule,
    LoaderModule,
    SharedModule,
  ],
  exports: [AuxModalViewComponent],
  providers: [MessageService, ConfirmationService],
})
export class CoreModule extends ModuleLoadedOnceGuard {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
