import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CONTACT_EMAIL } from '../../../../constants/help-menu.constants';

@Component({
  selector: 'carbon-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
      ]),
      transition(':leave', [animate('.1s linear', style({ opacity: 0 }))]),
    ]),
  ],
})
export class HelpMenuComponent {
  constructor(
    private translate: TranslateService,
    private router: Router,
  ) {}

  mailTo(): void {
    window.location.href = `mailto:${CONTACT_EMAIL}?subject=${this.translate.instant(
      `Contact.Subject`,
    )}&body=${this.translate.instant(`Contact.Body`)}`;
  }

  showAbout() {
    this.router.navigate([
      {
        outlets: {
          aux: ['modal', 'about'],
        },
      },
    ]);
  }
}
