import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { CarbonTreeDropdownComponent } from './components/carbon-tree-dropdown/carbon-tree-dropdown';
import { ControlErrorsModule } from './components/control-errors/control-errors.module';
import { EmptyScreenModule } from './components/empty-screen/empty-screen.module';
import { HelpIconModule } from './components/help-icon/help-icon.module';
import { ImageWithLoadingModule } from './components/image-with-loading/image-with-loading.module';
import { LoaderModule } from './components/loader/loader.module';
import { CommonDirectivesModule } from './directives/common-directives.module';
import { GetErrorPipe } from './pipes/get-error.pipe';
import { HighlightTextPipe } from './pipes/highlight-text.pipe';
import { LocaleNumberPipe } from './pipes/locale-number.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

@NgModule({
  declarations: [
    GetErrorPipe,
    TimeAgoPipe,
    LocaleNumberPipe,
    CarbonTreeDropdownComponent,
    HighlightTextPipe,
  ],

  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    TooltipModule,
    TranslateModule,
    ReactiveFormsModule,
    MultiSelectModule,
    InputTextModule,
    LoaderModule,
  ],

  exports: [
    CommonModule,
    ReactiveFormsModule,
    RippleModule,
    InputTextModule,
    CheckboxModule,
    ButtonModule,
    CardModule,
    ToastModule,
    HelpIconModule,
    ControlErrorsModule,
    CommonDirectivesModule,
    EmptyScreenModule,
    ImageWithLoadingModule,
    LoaderModule,
    GetErrorPipe,
    TimeAgoPipe,
    LocaleNumberPipe,
    HighlightTextPipe,
    CarbonTreeDropdownComponent,
  ],
})
export class SharedModule {}
