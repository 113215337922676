import { createAction, props } from '@ngrx/store';

import { ThemeMode } from '../../../shared/models/theme-mode.enum';

export const preferredThemeChange = createAction(
  '[User Preferences] Preferred Theme Change',
  props<{ theme: ThemeMode }>(),
);

export const preferredLanguageChange = createAction(
  '[User Preferences] Preferred Language Change',
  props<{ languageCode: string }>(),
);
