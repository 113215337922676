<ul class="user-menu layout-topbar-action-panel shadow-2" [@topbarActionPanelAnimation]="'visible'">
  <li class="text-grey-100 p-0" (click)="handleInfoClick($event)">
    <div class="grid">
      <div class="col-3 flex justify-content-center align-items-center">
        <p-avatar
          class="user-avatar"
          size="xlarge"
          label="{{ profile && profile.firstName ? profile.firstName[0] : '' }}{{
            profile && profile.lastName ? profile.lastName[0] : ''
          }}"
          shape="circle"
        ></p-avatar>
      </div>
      <div class="col-9 grid flex align-items-center name p-0">
        <span class="col-12 font-semibold text-black-alpha-90 pb-0 pt-4">
          {{ profile.firstName }} {{ profile.lastName }}</span
        >
        <span class="col-12 font-normal pb-3 pt-0">{{ profile.email }}</span>
      </div>
    </div>
  </li>
  <p-divider class="menu-divider"></p-divider>

  <li class="layout-topbar-action-item user-menu-item text-grey-100 border-0 pt-0 pb-0">
    <span pRipple class="layout-topbar-action rounded-circle link-cursor pt-0 pb-0 action" (click)="mailTo()">
      <i class="cb cb-icon-contact user-icon"></i>
      <span class="pl-3">{{ 'Home.HelpMenuLinks.Contact' | translate }}</span>
    </span>
  </li>

  <li class="layout-topbar-action-item user-menu-item text-grey-100 pt-0 pb-0">
    <span pRipple class="layout-topbar-action rounded-circle link-cursor pt-0 pb-0 action" (click)="showAbout()">
      <i class="cb cb-icon-info user-icon"></i>
      <span class="pl-3"> {{ 'Home.HelpMenuLinks.About' | translate }}</span>
    </span>
  </li>
  <p-divider class="menu-divider"></p-divider>
  <li class="layout-topbar-action-item user-menu-item text-grey-100 pt-0 pb-0">
    <span pRipple class="layout-topbar-action rounded-circle link-cursor pt-0 pb-0 action" (click)="onSignOutClick()">
      <i class="cb cb-icon-logout user-icon"></i>
      <span class="pl-3"> {{ 'Home.HelpMenuLinks.SignOut' | translate }}</span>
    </span>
  </li>
</ul>
