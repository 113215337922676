import { createReducer, on } from '@ngrx/store';

import { resetModalHeightWidth, setModalHeightWidth } from './actions/aux-modal.actions';
import { AuxModalState, initialState } from './models/aux-modal.state';

export const auxModalReducer = createReducer(
  initialState,

  on(setModalHeightWidth, (state, { width, height }): AuxModalState => {
    return {
      ...state,
      width,
      height,
    };
  }),

  on(resetModalHeightWidth, (state): AuxModalState => {
    return {
      ...state,
      width: initialState.width,
      height: initialState.height,
    };
  }),
);
