import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomErrorService {
  private errorSubject = new BehaviorSubject<string | null>(null);
  loginError$ = this.errorSubject.asObservable();

  setError(message: string) {
    this.errorSubject.next(message);
  }

  clearError() {
    this.errorSubject.next(null);
  }
}
