import { createReducer, on } from '@ngrx/store';

import {
  loadProduct,
  loadProductFailure,
  loadProductSuccess,
  resetSharePcf,
  setSharePcfPayload,
} from './actions/share-pcf.actions';
import { SharePcfState, initialState } from './models/share-pcf.state';

export const sharePcfReducer = createReducer(
  initialState,
  on(
    loadProduct,
    (state): SharePcfState => ({
      ...state,
      productLoading: true,
    }),
  ),

  on(
    loadProductSuccess,
    (state, { product }): SharePcfState => ({
      ...state,
      product,
      productLoading: false,
    }),
  ),

  on(
    loadProductFailure,
    (state): SharePcfState => ({
      ...state,
      productLoading: false,
    }),
  ),

  on(
    setSharePcfPayload,
    (state, { sharePcfPayload }): SharePcfState => ({
      ...state,
      sharePcfPayload,
    }),
  ),

  on(
    resetSharePcf,
    (state): SharePcfState => ({
      ...state,
      sharePcfPayload: null,
      product: null,
    }),
  ),
);
