import { Directive, ElementRef, OnInit, Self } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { EMPTY, Observable, fromEvent, merge, shareReplay, tap } from 'rxjs';

@Directive({
  selector: '[carbonFormSubmit]',
})
export class FormSubmitDirective implements OnInit {
  submit$: Observable<unknown> = merge(
    fromEvent(this.element, 'submit'),
    this.formGroupDirectiveNgSubmitObservable,
  ).pipe(
    tap(() => {
      if (!this.element.classList.contains('submitted')) {
        this.element.classList.add('submitted');
      }
    }),

    shareReplay(1),
  );

  reset$: Observable<Event> = fromEvent(this.element, 'reset').pipe(
    tap(() => {
      this.element.classList.remove('submitted');
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  get element() {
    return this.host.nativeElement;
  }

  get formGroupDirectiveNgSubmitObservable() {
    return this.formGroupDirective ? this.formGroupDirective.ngSubmit.asObservable() : EMPTY;
  }

  constructor(
    @Self() private formGroupDirective: FormGroupDirective,
    private host: ElementRef<HTMLFormElement>,
  ) {}

  isSubmitted() {
    return this.element.classList.contains('submitted');
  }

  ngOnInit() {
    this.element.classList.add('carbon-form');
  }
}
