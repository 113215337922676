<p-scrollPanel
  [style]="{ width: '100%', height: '200px' }"
  styleClass="layout-topbar-action-panel shadow-2"
  [@topbarActionPanelAnimation]="'visible'"
>
  <ul>
    <ng-container
      *ngIf="{
        notifications: (notifications$ | async),
        isLoading: (isLoading$ | async)
      } as notificationsData"
    >
      <ng-container *ngIf="notificationsData.isLoading; else noticationsTemplate">
        <carbon-loader></carbon-loader>
      </ng-container>
      <ng-template #noticationsTemplate>
        <li class="mb-3" (click)="handleInfoClick($event)">
          <span
            class="px-3 fs-small font-semibold"
            *ngIf="(notificationsData.notifications ?? []).length > 0; else noNotifications"
            >You have
            <span class="text-primary">{{ notificationsData.notifications?.length }}</span>
            notifications</span
          >
          <ng-template #noNotifications
            ><span class="px-3 fs-small font-semibold">You dont have any notifications</span></ng-template
          >
        </li>
        <ng-container *ngIf="(notificationsData.notifications ?? []).length > 0">
          <li
            class="layout-topbar-action-item"
            *ngFor="let notification of notificationsData.notifications"
            (click)="onNotificationClicked(notification)"
          >
            <div class="flex flex-row align-items-center">
              <div
                class="flex flex-column"
                style="flex-grow: 1"
                *ngIf="notification.eventType !== NotificationEventType.SecondaryRequestEmailSent"
              >
                <div class="flex align-items-center justify-content-between mb-1">
                  <span class="fs-small font-bold">
                    {{
                      notification.eventType === NotificationEventType.BOMUploaded
                        ? ('Global.CarbonBlock' | translate)
                        : notification.tenantName
                    }}
                  </span>
                  <small class="text-grey-100">{{ notification.created | timeAgo }}</small>
                </div>
                <carbon-notification-message [notification]="notification"></carbon-notification-message>
              </div>
              <div
                class="flex flex-column"
                style="flex-grow: 1"
                *ngIf="notification.eventType === NotificationEventType.SecondaryRequestEmailSent"
              >
                <div class="flex align-items-center justify-content-between mb-1">
                  <span class="fs-small font-bold">
                    {{ notification.message }}
                  </span>
                  <small class="text-grey-100 ml-1">{{ notification.created | timeAgo }}</small>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-template>
    </ng-container>
  </ul>
</p-scrollPanel>
