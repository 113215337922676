import { of } from 'rxjs';

import { AuthCognitoService } from '../../core/services/auth-cognito.service';
import { LoadingService } from '../../services';
import { SignUpForm } from './components/sign-up.component';

export function hexDecode(encoded: string) {
  const data = new Uint8Array(encoded.length / 2);
  for (let i = 0; i < encoded.length; i += 2) {
    data[i / 2] = parseInt(encoded.substring(i, i + 2), 16);
  }
  return new TextDecoder().decode(data);
}

export function initiateSignInProcess(
  formData: SignUpForm,
  authService: AuthCognitoService,
  oldPassword: string,
  loadingService: LoadingService,
) {
  return of(formData).pipe(
    loadingService.loadingSwitchMap(() => authService.signIn(formData.username, oldPassword)),
  );
}

export function initiatePerformSetPassword(
  formData: SignUpForm,
  authService: AuthCognitoService,
  user: unknown,
  loadingService: LoadingService,
) {
  return of(formData).pipe(
    loadingService.loadingSwitchMap(() => authService.completeNewPassword(user, formData.password)),
  );
}
