import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, forkJoin, map, of, repeat, switchMap } from 'rxjs';

import { ApiToStoreCallsService } from '../../../services';
import { RequestQuoteStorageService } from '../../../services/request-quote-storage.service';
import { handleErrorForExistingDomainUser } from '../../../shared/utilities/common-util/common-utils';
import {
  auxModalClickCancelOrClose,
  auxModalRefreshAfterClose,
} from '../../event-bus/actions/aux-modal.actions';
import {
  requestQuotesByCompIds,
  requestQuotesFailure,
  requestQuotesSuccess,
  updateComponentById,
  updateComponentFailure,
  updateComponentSuccess,
} from '../actions/components.actions';

@Injectable()
export class ComponentsEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiToStoreCallsService,
    private message: MessageService,
    private translate: TranslateService,
    private requestQuoteStorageService: RequestQuoteStorageService,
  ) {}

  updateComponentById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateComponentById),
      switchMap(({ payload }) => {
        return forkJoin([
          this.apiService.sendRequestPcf(payload, true),
          of(payload.componentId),
        ]).pipe(
          map(([, id]) => {
            this.message.add({
              severity: 'success',
              detail: this.translate.instant('RequestPcf.Contact.PcfSentMessage'),
            });

            return updateComponentSuccess({ id });
          }),
          catchError((error) => {
            handleErrorForExistingDomainUser(this.message, this.translate, error.response.status);
            return of(updateComponentFailure());
          }),
        );
      }),
    );
  });

  // TODO think how to avoid additional effect (we need it now because immediate modal close destroys action source)
  closeModalAfterUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateComponentSuccess),
      map(() => auxModalRefreshAfterClose({ showConfirmation: false })),
    );
  });

  requestQuotesByCompIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestQuotesByCompIds),
      switchMap(({ productId, componentIds }) =>
        this.apiService.componentRequestQuote(productId, componentIds),
      ),
      map((data: { productId: string; componentIds: string[] }) => {
        this.requestQuoteStorageService.setRequestedProductToStorage(
          data.productId,
          data.componentIds,
        );

        // Inform others that request quote is successfully done
        // From now on, websocket messages will be handled, if any received
        return requestQuotesSuccess();
      }),
      catchError(() => {
        this.message.add({
          severity: 'error',
          detail: this.translate.instant('RequestQuote.RequestQuoteFailureMessage'),
        });

        // Inform others that request quote is failed
        return of(requestQuotesFailure());
      }),
      repeat(), // Re-subscribe in case of error, so that we can trigger this stream again
    );
  });

  // Close aux modal after "request quote" network request to backend is initiated
  // (a.k.a button is clicked)
  closeModalAfterRequestQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestQuotesByCompIds),
      map(() => auxModalClickCancelOrClose()),
    );
  });
}
