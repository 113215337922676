import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { ModalViewConfig } from '../models';
import { MODAL_VIEW_CONFIG } from '../tokens/modal.tokens';

@Component({
  selector: 'carbon-modal-view-container',
  templateUrl: './modal-view-container.component.html',
  styleUrls: ['./modal-view-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalViewContainerComponent {
  @Input()
  modalClasses: string[] = [];

  @Input()
  showStepper = true;

  @Input()
  showCloseBtn = true;

  @Input()
  showHeader = true;

  @Input()
  showFooter = true;

  @Output()
  closeModal = new EventEmitter<MouseEvent>();
  stepperItems$: Observable<MenuItem[] | undefined>;
  modalViewConfig$: Observable<ModalViewConfig>;

  constructor(
    @Inject(MODAL_VIEW_CONFIG) private modalViewConfig: BehaviorSubject<ModalViewConfig>,
    private store: Store,
  ) {
    this.modalViewConfig$ = this.modalViewConfig.asObservable();
    this.stepperItems$ = this.modalViewConfig$.pipe(
      map((modalViewConfig: ModalViewConfig) => modalViewConfig.stepperItems),
    );
  }

  onModalClose(event: MouseEvent) {
    this.closeModal.emit(event);
  }
}
