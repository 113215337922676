import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable, of } from 'rxjs';

import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'carbon-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  menuItems$: Observable<MenuItem[]>;
  homeLink$: Observable<MenuItem>;

  constructor(private breadcrumbService: BreadcrumbService) {
    this.menuItems$ = this.breadcrumbService.breadcrumbMenuItems$;
    this.homeLink$ = of({
      icon: 'pi pi-home',
      routerLink: '/',
    });
  }
}
