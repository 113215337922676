import { NgModule } from '@angular/core';
import { BlockUIModule } from 'primeng/blockui';
import { PanelModule } from 'primeng/panel';

import { SharedModule } from '../../shared/shared.module';
import { LoadingBlockableComponent } from './components/loading-blockable/loading-blockable.component';
import { LoadingContainerComponent } from './components/loading-container/loading-container.component';

@NgModule({
  declarations: [LoadingContainerComponent, LoadingBlockableComponent],
  imports: [SharedModule, BlockUIModule, PanelModule],
  exports: [LoadingContainerComponent],
})
export class LoadingContainerModule {}
