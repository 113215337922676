import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, take, takeUntil, tap } from 'rxjs';

import { stopPropagation } from '../../../../../shared/utilities';
import { UserProfileState } from '../../../../../store/user-profile/models/user-profile.state';
import { selectUserProfileState } from '../../../../../store/user-profile/selectors/user-profile.selectors';
import { CONTACT_EMAIL } from '../../../../constants/help-menu.constants';
import { AuthCognitoService } from '../../../../services/auth-cognito.service';

@Component({
  selector: 'carbon-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
      ]),
      transition(':leave', [animate('.1s linear', style({ opacity: 0 }))]),
    ]),
  ],
})
export class UserMenuComponent implements OnDestroy {
  profile: UserProfileState;
  protected destroy$$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private authService: AuthCognitoService,
    private translate: TranslateService,
  ) {
    this.profile = {} as UserProfileState;
    this.store
      .select(selectUserProfileState)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((profile) => {
        this.profile = profile;
      });
  }

  mailTo(): void {
    window.location.href = `mailto:${CONTACT_EMAIL}?subject=${this.translate.instant(
      `Contact.Subject`,
    )}&body=${this.translate.instant(`Contact.Body`)}`;
  }

  showAbout() {
    this.router.navigate([
      {
        outlets: {
          aux: ['modal', 'about'],
        },
      },
    ]);
  }

  onSignOutClick(): void {
    this.authService
      .signOut()
      .pipe(
        tap(() => localStorage.clear()),
        take(1),
      )
      .subscribe(() => {
        // do a page refresh just to make sure aws objects were cleared after logout
        this.router.navigateByUrl('login').then(() => document.location.reload());
      });
  }

  handleInfoClick(event: Event): void {
    stopPropagation(event);
  }

  ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
