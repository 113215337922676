import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, takeUntil } from 'rxjs';

import { ApiToStoreCallsService } from '../../../../../services';
import {
  Notification,
  NotificationEventType,
} from '../../../../../shared/models/notification.interface';
import { stopPropagation, untilDestroy } from '../../../../../shared/utilities';

@Component({
  selector: 'carbon-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
      ]),
      transition(':leave', [animate('.1s linear', style({ opacity: 0 }))]),
    ]),
  ],
})
export class NotificationsMenuComponent extends untilDestroy(class {}) {
  notifications$: Observable<Notification[]>;
  protected isLoading$ = this.apiService.isLoading$;
  NotificationEventType = NotificationEventType;

  constructor(
    private router: Router,
    private apiService: ApiToStoreCallsService,
  ) {
    super();
    this.notifications$ = this.apiService.getNotifications().pipe(takeUntil(this.destroy$$));
  }

  onNotificationClicked(notification: Notification) {
    this.apiService
      .updateNotification(notification.messageId)
      .pipe(takeUntil(this.destroy$$))
      .subscribe();
    switch (notification.eventType) {
      case NotificationEventType.RequestForPCF:
        this.router.navigate(['requests/overview/inbound']);
        break;
      case NotificationEventType.AnswerForPCFRequest:
      case NotificationEventType.UpdatedAnswerForPCFRequest:
      case NotificationEventType.UpdatedComponentValuesAvailable:
        this.router.navigate([
          'products/component',
          notification.productId,
          notification.componentId,
        ]);
        break;
      case NotificationEventType.BOMUploaded:
        this.router.navigate(['products/details', notification.productId]);
        break;
    }
  }

  handleInfoClick(event: Event): void {
    stopPropagation(event);
  }
}
